import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/recommendActions';

interface RecommendState {
  isLoading: boolean;
  error: string | null;
  years: number[];
  recommends: {
    year: number;
    details: {
      month: number;
      songs: {
        performerId: number;
        performerName: string;
        songNo: string;
        songName: string;
        isPublic: boolean;
      }[];
    }[];
  } | null;
  recommendsByMonth: {
    detailSeq: number;
    mainSeq: number;
    year: number;
    month: number;
    performerId: number;
    performerName: string;
    songNo: string;
    songName: string;
    videoUrl: string;
    onlineStartDt: string;
    onlineEndDt: string;
    updateDate: string;
    isPublic: boolean;
  }[];
  performers: {
    performerId: number;
    performerName: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  years: [],
  recommends: null,
  recommendsByMonth: [],
  performers: [],
};

const recommendReducer = (
  state: RecommendState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_YEARS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_YEARS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: action.payload,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_YEARS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        recommends: action.payload,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_BY_MONTH_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_BY_MONTH_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: action.payload,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMENDS_BY_MONTH_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.CREATE_YEAR_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.CREATE_YEAR_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: action.payload,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.CREATE_YEAR_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.UPDATE_RECOMMEND_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.UPDATE_RECOMMEND_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: action.payload,
        performers: state.performers,
      };
    case ActionType.UPDATE_RECOMMEND_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMEND_PERFORMERS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    case ActionType.FETCH_RECOMMEND_PERFORMERS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: action.payload,
      };
    case ActionType.FETCH_RECOMMEND_PERFORMERS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        recommends: state.recommends,
        recommendsByMonth: state.recommendsByMonth,
        performers: state.performers,
      };
    default:
      return state;
  }
};

export default recommendReducer;

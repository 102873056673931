import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/app/appVersionActions';

export interface AppVersionState {
  isLoading: boolean;
  error: string | null;
  appVersion: {
    test: {
      seq: number;
      version: string;
      osVersion: string;
      appVersion: string;
      appStore: string;
      releaseTime: string;
    }[];
    release: {
      seq: number;
      version: string;
      osVersion: string;
      appVersion: string;
      appStore: string;
      releaseTime: string;
    }[];
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  appVersion: null,
};

const appVersionReducer = (
  state: AppVersionState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_APP_VERSION_START:
      return {
        isLoading: true,
        error: null,
        appVersion: null,
      };
    case ActionType.FETCH_APP_VERSION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        appVersion: action.payload,
      };
    case ActionType.FETCH_APP_VERSION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        appVersion: null,
      };
    case ActionType.UPDATE_APP_VERSION_START:
      return {
        isLoading: true,
        error: null,
        appVersion: state.appVersion,
      };
    case ActionType.UPDATE_APP_VERSION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        appVersion: state.appVersion,
      };
    case ActionType.UPDATE_APP_VERSION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        appVersion: state.appVersion,
      };
    default:
      return state;
  }
};

export default appVersionReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/menu/menuActions';

export interface MenuState {
  isLoading: boolean;
  error: string | null;
  menuList: {
    title: string;
    key: string;
    menuIcon: string;
    children: {
      title: string;
      key: string;
      children: {
        title: string;
        key: string;
        path: string;
        oldPath?: string;
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  menuList: [],
};

const menuReducer = (state: MenuState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_MENU_LIST_START:
      return {
        isLoading: true,
        error: null,
        menuList: state.menuList,
      };
    case ActionType.FETCH_MENU_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        menuList: action.payload,
      };
    case ActionType.FETCH_MENU_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        menuList: state.menuList,
      };
    default:
      return state;
  }
};

export default menuReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/crew/crewPointLockActions';

export interface CrewPointLockState {
  isLoading: boolean;
  error: string | null;
  isLocked: boolean;
  closeDate: string;
}

const initialState = {
  isLoading: false,
  error: null,
  isLocked: true,
  closeDate: '',
};

const crewPointLockReducer = (
  state: CrewPointLockState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_LOCK_STATUS_START:
      return {
        isLoading: true,
        error: null,
        isLocked: state.isLocked,
        closeDate: state.closeDate,
      };
    case ActionType.FETCH_LOCK_STATUS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        isLocked: action.payload.isLocked,
        closeDate: action.payload.closeDate,
      };
    case ActionType.FETCH_LOCK_STATUS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        isLocked: state.isLocked,
        closeDate: state.closeDate,
      };
    case ActionType.UPDATE_LOCK_STATUS_START:
      return {
        isLoading: true,
        error: null,
        isLocked: state.isLocked,
        closeDate: state.closeDate,
      };
    case ActionType.UPDATE_LOCK_STATUS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        isLocked: state.isLocked,
        closeDate: state.closeDate,
      };
    case ActionType.UPDATE_LOCK_STATUS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        isLocked: state.isLocked,
        closeDate: state.closeDate,
      };
    default:
      return state;
  }
};

export default crewPointLockReducer;

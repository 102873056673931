import { Spin } from 'antd';

import './Spinner.css';

interface SpinnerProps {
  text: string;
}

const Spinner = ({ text }: SpinnerProps) => {
  return (
    <div className="overlay">
      <Spin size="large" className="spinner" tip={text} />
    </div>
  );
};

export default Spinner;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/eventPropActions';

interface EventPropState {
  isLoading: boolean;
  error: string | null;
  props: {
    seq: number;
    word: string;
    wordDesc: string;
    imgUrl: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  props: [],
};

const eventPropReducer = (
  state: EventPropState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_PROPS_LIST_START:
      return {
        isLoading: true,
        error: null,
        props: state.props,
      };
    case ActionType.FETCH_EVENT_PROPS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        props: action.payload,
      };
    case ActionType.FETCH_EVENT_PROPS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        props: state.props,
      };
    case ActionType.UPDATE_EVENT_PROP_START:
      return {
        isLoading: true,
        error: null,
        props: state.props,
      };
    case ActionType.UPDATE_EVENT_PROP_SUCCESS:
      return {
        isLoading: false,
        error: null,
        props: state.props.map((prop) => {
          if (prop.seq === action.payload.seq) {
            return action.payload;
          }
          return prop;
        }),
      };
    case ActionType.UPDATE_EVENT_PROP_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        props: state.props,
      };
    default:
      return state;
  }
};

export default eventPropReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/crewSeatComparisonActions';

interface CrewSeatComparisonState {
  isLoading: boolean;
  error: string | null;
  crewSeats: {
    seq: number;
    computerName: string;
    extensionNumber: string;
  }[];
  selectSeat: {
    seq: number;
    computerName: string;
    extensionNumber: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  crewSeats: [],
  selectSeat: null,
};

const crewSeatComparisonReducer = (
  state: CrewSeatComparisonState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_TE_CREW_SEATS_LIST_START:
      return {
        isLoading: true,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.FETCH_TE_CREW_SEATS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewSeats: action.payload,
        selectSeat: null,
      };
    case ActionType.FETCH_TE_CREW_SEATS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.DELETE_TE_CREW_SEAT_START:
      return {
        isLoading: true,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.DELETE_TE_CREW_SEAT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewSeats: state.crewSeats.filter(
          (seat) => seat.seq !== action.payload
        ),
        selectSeat: null,
      };
    case ActionType.DELETE_TE_CREW_SEAT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.CREATE_TE_CREW_SEAT_START:
      return {
        isLoading: true,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.CREATE_TE_CREW_SEAT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewSeats: [...state.crewSeats, action.payload],
        selectSeat: null,
      };
    case ActionType.CREATE_TE_CREW_SEAT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.FETCH_TE_CREW_SEAT_START:
      return {
        isLoading: true,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.FETCH_TE_CREW_SEAT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: action.payload,
      };
    case ActionType.FETCH_TE_CREW_SEAT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewSeats: state.crewSeats,
        selectSeat: null,
      };
    case ActionType.UPDATE_TE_CREW_SEAT_START:
      return {
        isLoading: true,
        error: null,
        crewSeats: state.crewSeats,
        selectSeat: state.selectSeat,
      };
    case ActionType.UPDATE_TE_CREW_SEAT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewSeats: state.crewSeats.map((seat) => {
          if (seat.seq === action.payload.seq) {
            return action.payload;
          }
          return seat;
        }),
        selectSeat: state.selectSeat,
      };
    case ActionType.UPDATE_TE_CREW_SEAT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewSeats: state.crewSeats,
        selectSeat: state.selectSeat,
      };
    default:
      return state;
  }
};

export default crewSeatComparisonReducer;

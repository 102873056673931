import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/gift/giftLabelActions';

export interface GiftLabelState {
  isLoading: boolean;
  error: string | null;
  giftLabels: {
    mainSeq: number;
    printDate: string;
    memberNo: string;
    memberName: string;
    memberSex: string;
    zipCode: string;
    address: string;
    exchangeGifts: {
      exchangeDate: string;
      giftDetails: {
        seq: number;
        giftNo: string;
        giftName: string;
        numberOfExchanges: number;
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  giftLabels: [],
};

const giftLabelReducer = (
  state: GiftLabelState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_GIFT_LABELS_LIST_START:
      return {
        isLoading: true,
        error: null,
        giftLabels: state.giftLabels,
      };
    case ActionType.FETCH_GIFT_LABELS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        giftLabels: action.payload,
      };
    case ActionType.FETCH_GIFT_LABELS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        giftLabels: state.giftLabels,
      };
    case ActionType.FETCH_GIFT_LABELS_LIST_BY_RANGE_START:
      return {
        isLoading: true,
        error: null,
        giftLabels: state.giftLabels,
      };
    case ActionType.FETCH_GIFT_LABELS_LIST_BY_RANGE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        giftLabels: action.payload,
      };
    case ActionType.FETCH_GIFT_LABELS_LIST_BY_RANGE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        giftLabels: state.giftLabels,
      };
    case ActionType.UPDATE_GIFT_LABELS_STATUS_START:
      return {
        isLoading: true,
        error: null,
        giftLabels: state.giftLabels,
      };
    case ActionType.UPDATE_GIFT_LABELS_STATUS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        giftLabels: state.giftLabels,
      };
    case ActionType.UPDATE_GIFT_LABELS_STATUS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        giftLabels: state.giftLabels,
      };
    default:
      return state;
  }
};

export default giftLabelReducer;

import logo from '../../assets/images/Logo_WFClubAdmin.svg';

interface LogoProps {
  width: number;
}

const Logo = ({ width }: LogoProps) => {
  return (
    <div style={{ width }}>
      <img
        src={logo}
        alt="world family english logo"
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};

export default Logo;

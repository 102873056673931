import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/eventWordActions';

interface EventWordState {
  isLoading: boolean;
  error: string | null;
  words: {
    seq: number;
    cardNo: string;
    englishWord: string;
    chineseWord: string;
    blobUrl: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  words: [],
};

const eventWordReducer = (
  state: EventWordState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_WORDS_LIST_START:
      return {
        isLoading: true,
        error: null,
        words: state.words,
      };
    case ActionType.FETCH_EVENT_WORDS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        words: action.payload,
      };
    case ActionType.FETCH_EVENT_WORDS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        words: state.words,
      };
    case ActionType.UPDATE_EVENT_WORD_START:
      return {
        isLoading: true,
        error: null,
        words: state.words,
      };
    case ActionType.UPDATE_EVENT_WORD_SUCCESS:
      return {
        isLoading: false,
        error: null,
        words: state.words.map((word) => {
          if (word.seq === action.payload.seq) {
            return action.payload;
          }
          return word;
        }),
      };
    case ActionType.UPDATE_EVENT_WORD_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        words: state.words,
      };
    default:
      return state;
  }
};

export default eventWordReducer;

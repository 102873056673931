import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/programMenuActions';

export interface ProgramMenuState {
  isLoading: boolean;
  error: string | null;
  programMenu: {
    menuSeq: number;
    menuEnglishName: string;
    menuChineseName: string;
    menuIcon: string;
    sort: number;
  }[];
  selectProgramMenu: {
    menuSeq: number;
    menuEnglishName: string;
    menuChineseName: string;
    menuIcon: string;
    sort: number;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  programMenu: [],
  selectProgramMenu: null,
};

const programMenuReducer = (
  state: ProgramMenuState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PROGRAM_MENU_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.FETCH_PROGRAM_MENU_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: action.payload,
        selectProgramMenu: null,
      };
    case ActionType.FETCH_PROGRAM_MENU_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_SORT_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_SORT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_SORT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.DELETE_PROGRAM_MENU_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.DELETE_PROGRAM_MENU_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: state.programMenu.filter(
          (menu) => menu.menuSeq !== action.payload
        ),
        selectProgramMenu: null,
      };
    case ActionType.DELETE_PROGRAM_MENU_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.CREATE_PROGRAM_MENU_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.CREATE_PROGRAM_MENU_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: [...state.programMenu, action.payload],
        selectProgramMenu: null,
      };
    case ActionType.CREATE_PROGRAM_MENU_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.FETCH_ONE_PROGRAM_MENU_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.FETCH_ONE_PROGRAM_MENU_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: action.payload,
      };
    case ActionType.FETCH_ONE_PROGRAM_MENU_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_START:
      return {
        isLoading: true,
        error: null,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programMenu: state.programMenu.map((menu) => {
          if (menu.menuSeq === action.payload.menuSeq) {
            return action.payload;
          }
          return menu;
        }),
        selectProgramMenu: null,
      };
    case ActionType.UPDATE_PROGRAM_MENU_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programMenu: state.programMenu,
        selectProgramMenu: null,
      };
    default:
      return state;
  }
};

export default programMenuReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sms/nameListActions';

export interface nameListState {
  isLoading: boolean;
  error: string | null;
  fixedList: {
    empCode: string;
    name: string;
  }[];
  otherList: {
    empCode: string;
    name: string;
  }[];
  optionalList: {
    empCode: string;
    name: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  fixedList: [],
  otherList: [],
  optionalList: [],
};

const nameListReducer = (
  state: nameListState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SMS_FIXED_NAME_LIST_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_FIXED_NAME_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: action.payload,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_FIXED_NAME_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_OTHER_LIST_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_OTHER_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: state.fixedList,
        otherList: action.payload,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_OTHER_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_OPTIONAL_NAME_LIST_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.FETCH_SMS_OPTIONAL_NAME_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: action.payload,
      };
    case ActionType.FETCH_SMS_OPTIONAL_NAME_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.CREATE_SMS_FIXED_NAMES_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.CREATE_SMS_FIXED_NAMES_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: [...state.fixedList, ...action.payload],
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.CREATE_SMS_FIXED_NAMES_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.CREATE_SMS_OPTIONAL_NAMES_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.CREATE_SMS_OPTIONAL_NAMES_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: [...state.optionalList, ...action.payload],
      };
    case ActionType.CREATE_SMS_OPTIONAL_NAMES_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.DELETE_SMS_FIXED_NAME_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.DELETE_SMS_FIXED_NAME_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: state.fixedList.filter(
          (name) => name.empCode !== action.payload
        ),
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.DELETE_SMS_FIXED_NAME_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.DELETE_SMS_OPTIONAL_NAME_START:
      return {
        isLoading: true,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    case ActionType.DELETE_SMS_OPTIONAL_NAME_SUCCESS:
      return {
        isLoading: false,
        error: null,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList.filter(
          (name) => name.empCode !== action.payload
        ),
      };
    case ActionType.DELETE_SMS_OPTIONAL_NAME_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        fixedList: state.fixedList,
        otherList: state.otherList,
        optionalList: state.optionalList,
      };
    default:
      return state;
  }
};

export default nameListReducer;

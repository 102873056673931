import { Layout } from 'antd';

import './Footer.css';

const Footer = () => {
  return (
    <Layout.Footer className="footer">
      Copyright World Family English, Ltd. &copy; 2021. All Rights Reserved.
    </Layout.Footer>
  );
};

export default Footer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/crew/crewListActions';

export interface CrewListState {
  isLoading: boolean;
  error: string | null;
  activeCrewList: {
    empNo: string;
    firstName: string;
    lastName: string;
    chineseName: string;
    crewType: number[];
    onBoard: string;
    isLeaved: boolean;
    email: string;
    remark: string;
    mobile: string;
    actPointsValue: number | null;
    tePointsValue: number | null;
    webexUrl: string | null;
    zoomId: string | null;
    zoomPw: string | null;
    organizer: string | null;
    userId: string | null;
  }[];
  leaveCrewList: {
    empNo: string;
    firstName: string;
    lastName: string;
    chineseName: string;
    crewType: number[];
    onBoard: string;
    isLeaved: boolean;
    email: string;
    remark: string;
    mobile: string;
    actPointsValue: number | null;
    tePointsValue: number | null;
    webexUrl: string | null;
    zoomId: string | null;
    zoomPw: string | null;
    organizer: string | null;
    userId: string | null;
  }[];
  empList: {
    empNo: string;
    empEnglishName: string;
    empChineseName: string;
    mobile: string;
    email: string;
    onBoard: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  activeCrewList: [],
  leaveCrewList: [],
  empList: [],
};

const crewListReducer = (
  state: CrewListState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CREW_LIST_START:
      return {
        isLoading: true,
        error: null,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.FETCH_CREW_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        activeCrewList: action.payload.filter((crew) => !crew.isLeaved),
        leaveCrewList: action.payload.filter((crew) => crew.isLeaved),
        empList: state.empList,
      };
    case ActionType.FETCH_CREW_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.FETCH_EMP_LIST_START:
      return {
        isLoading: true,
        error: null,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.FETCH_EMP_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: action.payload,
      };
    case ActionType.FETCH_EMP_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.ADD_CREW_START:
      return {
        isLoading: true,
        error: null,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.ADD_CREW_SUCCESS:
      return {
        isLoading: false,
        error: null,
        activeCrewList: [...state.activeCrewList, action.payload],
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.ADD_CREW_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.UPDATE_CREW_START:
      return {
        isLoading: true,
        error: null,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.UPDATE_CREW_SUCCESS:
      return {
        isLoading: false,
        error: null,
        activeCrewList: action.payload.isLeaved
          ? state.activeCrewList
          : state.activeCrewList.map((crew) => {
              if (crew.empNo === action.payload.empNo) {
                return action.payload;
              }
              return crew;
            }),
        leaveCrewList: action.payload.isLeaved
          ? state.leaveCrewList.map((crew) => {
              if (crew.empNo === action.payload.empNo) {
                return action.payload;
              }
              return crew;
            })
          : state.leaveCrewList,
        empList: state.empList,
      };
    case ActionType.UPDATE_CREW_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        activeCrewList: state.activeCrewList,
        leaveCrewList: state.leaveCrewList,
        empList: state.empList,
      };
    default:
      return state;
  }
};

export default crewListReducer;

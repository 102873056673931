import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/cap/capApplyActions';

export interface CapApplyState {
  isLoading: boolean;
  error: string | null;
  childList: {
    childSeq: string;
    childName: string;
    capApplyCount: number;
  }[];
  capItems: {
    capItemNo: number;
    capItemName: string;
    capItemType: string;
    verifyMsg_Tw: string;
    verifyMsg_En: string;
  }[];
  subItems: {
    songNo: string;
    songName: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  childList: [],
  capItems: [],
  subItems: [],
};

const capApplyReducer = (
  state: CapApplyState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CHILD_LIST_START:
      return {
        isLoading: true,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_CHILD_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childList: action.payload,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_CHILD_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_CAP_ITEMS_LIST_START:
      return {
        isLoading: true,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_CAP_ITEMS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childList: state.childList,
        capItems: action.payload,
        subItems: state.subItems,
      };
    case ActionType.FETCH_CAP_ITEMS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_SUB_ITEMS_LIST_START:
      return {
        isLoading: true,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.FETCH_SUB_ITEMS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: action.payload,
      };
    case ActionType.FETCH_SUB_ITEMS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.CAP_APPLY_START:
      return {
        isLoading: true,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.CAP_APPLY_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    case ActionType.CAP_APPLY_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childList: state.childList,
        capItems: state.capItems,
        subItems: state.subItems,
      };
    default:
      return state;
  }
};

export default capApplyReducer;

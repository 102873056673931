import { ActionType } from '../../actions/ActionType';
import { Action, BannerType } from '../../actions/app/appBannerActions';

interface AppBannerState {
  isLoading: boolean;
  error: string | null;
  banners: {
    inUseBanners: BannerType[];
    notUseBanners: BannerType[];
  } | null;
  selectBanner: BannerType | null;
}

const initialState = {
  isLoading: false,
  error: null,
  banners: null,
  selectBanner: null,
};

const appBannerReducer = (
  state: AppBannerState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_APP_BANNERS_START:
      return {
        isLoading: true,
        error: null,
        banners: null,
        selectBanner: null,
      };
    case ActionType.FETCH_APP_BANNERS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: action.payload,
        selectBanner: null,
      };
    case ActionType.FETCH_APP_BANNERS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: null,
        selectBanner: null,
      };
    case ActionType.FETCH_APP_BANNER_START:
      return {
        isLoading: true,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_APP_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: state.banners,
        selectBanner: action.payload,
      };
    case ActionType.FETCH_APP_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.CREATE_APP_BANNER_START:
      return {
        isLoading: true,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.CREATE_APP_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: state.banners,
        selectBanner: action.payload,
      };
    case ActionType.CREATE_APP_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.UPDATE_APP_BANNER_START:
      return {
        isLoading: true,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.UPDATE_APP_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: state.banners,
        selectBanner: action.payload,
      };
    case ActionType.UPDATE_APP_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.DELETE_APP_BANNER_START:
      return {
        isLoading: true,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.DELETE_APP_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.DELETE_APP_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.RESORT_APP_BANNERS_START:
      return {
        isLoading: true,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.RESORT_APP_BANNERS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.RESORT_APP_BANNERS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    default:
      return state;
  }
};

export default appBannerReducer;

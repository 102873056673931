// @ts-nocheck
import { useState, useEffect, useCallback, Suspense } from 'react';
import { useMsal } from '@azure/msal-react';
import { Layout, message } from 'antd';
import axios from 'axios';
import './App.css';
import Spinner from './components/spinner/Spinner';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SignIn from './pages/signIn/SignIn';
import { loginRequest } from './auth/authConfig';
import { callMsGraph } from './auth/graphConfig';
import { getApiUrl } from './utils/getApiUrl';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { UserCodeContext } from './context/UserCodeContext';
import Routes from './routes/Routes';
import ErrorFallback from './components/errorFallback/ErrorFallback';

const App = ({ setLocale, locale, history }: any) => {
  const [graphData, setGraphData] = useState<any>(null);
  const [userCode, setUserCode] = useState<any>(null);

  // MSAL authentication
  const { instance, accounts, inProgress }: any = useMsal();
  const requestProfileData = useCallback(() => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response: any) => {
        // TODO: ↓↓ remove after test fetching data ↓↓
        // const sessionStorageKey = `${response.account.homeAccountId}-login.windows.net-accesstoken-${response.account.idTokenClaims.aud}-${response.tenantId}-openid profile user.read email`;
        // const userInfoJSONString = window.sessionStorage.getItem(
        //   sessionStorageKey
        // );
        // let userInfo;
        // if (typeof userInfoJSONString === 'string') {
        //   userInfo = JSON.parse(userInfoJSONString);
        // }
        // console.log(userInfo.secret === response.accessToken);
        // TODO: ↑↑ remove after test fetching data ↑↑

        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      })
      .catch((err: any) => {
        message.error('User authentication failed. Please re-login again.', 0);
        window.localStorage.clear();
        setTimeout(() => {
          history.push('/');
          window.location.reload();
        }, 3000);
      });
  }, [instance, accounts, history]);

  useEffect(() => {
    if (accounts.length > 0) {
      requestProfileData();
    }
  }, [accounts, requestProfileData]);

  useEffect(() => {
    const getUserCode = async (mail: string) => {
      const apiUrl = await getApiUrl();
      const { data } = await axios.get(`${apiUrl}/employee/${mail}`);
      setUserCode(data.code);
    };
    if (graphData) {
      getUserCode(graphData.mail);
    }
  }, [graphData]);

  const { Content } = Layout;

  if (accounts.length > 0) {
    // @ts-ignore
    return graphData && userCode ? (
      <Layout>
        <Header
          currentUser={graphData.displayName}
          instance={instance}
          setLocale={setLocale}
          locale={locale}
        />
        <Content className="content-container">
          <Layout className="content-container-inner">
            <ErrorBoundary FallbackComponent={ErrorFallback}>

              <Suspense fallback={<Spinner text="Loading..." />}>

                <UserCodeContext.Provider value={userCode}>

                  <Routes />

                </UserCodeContext.Provider>

              </Suspense>

            </ErrorBoundary>
          </Layout>
        </Content>
        <Footer />
      </Layout>
    ) : (
      <Spinner text="Loading..." />
      // <ErrorFallback />
    );
  } else if (inProgress === 'login') {
    return <Spinner text="Signing In..." />;
  } else {
    return <SignIn instance={instance} />;
  }
};

export default withRouter(App);

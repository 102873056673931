import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/eventNoticeActions';

interface EventNoticeState {
  isLoading: boolean;
  error: string | null;
  mainTypes: {
    typeNo: string;
    typeDesc: string;
  }[];
  subTypes: {
    typeNo: string;
    typeDesc: string;
  }[];
  eventTypeDescription: {
    seq: number;
    contents: string;
  } | null;
  eventNotice: {
    seq: number;
    contents: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  mainTypes: [],
  subTypes: [],
  eventTypeDescription: null,
  eventNotice: [],
};

const eventNoticeReducer = (
  state: EventNoticeState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_MAIN_TYPES_LIST_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_MAIN_TYPES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: action.payload,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_MAIN_TYPES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_SUB_TYPES_LIST_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_SUB_TYPES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: action.payload,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_SUB_TYPES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_DESCRIPTION_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_DESCRIPTION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: action.payload,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_DESCRIPTION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.FETCH_EVENT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: action.payload,
      };
    case ActionType.FETCH_EVENT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.UPDATE_EVENT_DESCRIPTION_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.UPDATE_EVENT_DESCRIPTION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: action.payload,
        eventNotice: state.eventNotice,
      };
    case ActionType.UPDATE_EVENT_DESCRIPTION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.CREATE_EVENT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.CREATE_EVENT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: [...state.eventNotice, action.payload],
      };
    case ActionType.CREATE_EVENT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.DELETE_EVENT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.DELETE_EVENT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice.filter(
          (item) => item.seq !== action.payload
        ),
      };
    case ActionType.DELETE_EVENT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.UPDATE_EVENT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.UPDATE_EVENT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice.map((item) => {
          if (item.seq === action.payload.seq) {
            return action.payload;
          }
          return item;
        }),
      };
    case ActionType.UPDATE_EVENT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.SORT_EVENT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.SORT_EVENT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.SORT_EVENT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.CREATE_EVENT_TYPE_DESCRIPTION_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    case ActionType.CREATE_EVENT_TYPE_DESCRIPTION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: action.payload,
        eventNotice: state.eventNotice,
      };
    case ActionType.CREATE_EVENT_TYPE_DESCRIPTION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        subTypes: state.subTypes,
        eventTypeDescription: state.eventTypeDescription,
        eventNotice: state.eventNotice,
      };
    default:
      return state;
  }
};

export default eventNoticeReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/mailOrder/shippingDetailActions';

interface ShippingDetailState {
  isLoading: boolean;
  error: string | null;
  sellerList: {
    salesEname: string;
  }[];
  subtotal: {
    giftName: String;
    qty: Number;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  sellerList: [],
  subtotal: [],
};

const shippingDetailReducer = (
  state: ShippingDetailState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SELLER_LIST_START:
      return {
        isLoading: true,
        error: null,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.FETCH_SELLER_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        sellerList: action.payload,
        subtotal: state.subtotal,
      };
    case ActionType.FETCH_SELLER_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.DL_SHIPPING_DETAIL_START:
      return {
        isLoading: true,
        error: null,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.DL_SHIPPING_DETAIL_SUCCESS:
      return {
        isLoading: false,
        error: null,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.DL_SHIPPING_DETAIL_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.FETCH_MAIL_ORDER_SUBTOTAL_START:
      return {
        isLoading: true,
        error: null,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    case ActionType.FETCH_MAIL_ORDER_SUBTOTAL_SUCCESS:
      return {
        isLoading: false,
        error: null,
        sellerList: state.sellerList,
        subtotal: action.payload,
      };
    case ActionType.FETCH_MAIL_ORDER_SUBTOTAL_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        sellerList: state.sellerList,
        subtotal: state.subtotal,
      };
    default:
      return state;
  }
};

export default shippingDetailReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sms/eventListAction';

export interface eventListState {
  isLoading: boolean;
  error: string | null;
  eventList: {
    no: string;
    date: string;
    name: string;
  }[];
  areaList: {
    no: string;
    name: string;
  }[];
  noAppOrPhoneList: {
    no: string;
    name: string;
  }[];
  notRegList: {
    no: string;
    name: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  eventList: [],
  areaList: [],
  noAppOrPhoneList: [],
  notRegList: [],
};

const eventListReducer = (
  state: eventListState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_AREA_LIST_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_EVENT_AREA_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: state.eventList,
        areaList: action.payload,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_EVENT_AREA_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_EVENT_LIST_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_EVENT_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: action.payload,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_EVENT_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_NO_APP_OR_PHONE_LIST_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_NO_APP_OR_PHONE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: action.payload,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_SMS_NO_APP_OR_PHONE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.SEND_EVENT_SMS_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.SEND_EVENT_SMS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: [],
        areaList: state.areaList,
        noAppOrPhoneList: [],
        notRegList: [],
      };
    case ActionType.SEND_EVENT_SMS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_PROMO_COUNT_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_PROMO_COUNT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: action.payload,
      };
    case ActionType.FETCH_PROMO_COUNT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_PROMO_NO_APP_OR_PHONE_LIST_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_PROMO_NO_APP_OR_PHONE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: action.payload,
        notRegList: state.notRegList,
      };
    case ActionType.FETCH_PROMO_NO_APP_OR_PHONE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.SEND_PROMO_SMS_START:
      return {
        isLoading: true,
        error: null,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    case ActionType.SEND_PROMO_SMS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventList: [],
        areaList: state.areaList,
        noAppOrPhoneList: [],
        notRegList: [],
      };
    case ActionType.SEND_PROMO_SMS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventList: state.eventList,
        areaList: state.areaList,
        noAppOrPhoneList: state.noAppOrPhoneList,
        notRegList: state.notRegList,
      };
    default:
      return state;
  }
};

export default eventListReducer;

import { combineReducers } from 'redux';

import capItemRelationshipReducer from './cap/capItemRelationshipReducer';
import capCheckingReducer from './cap/capCheckingReducer';
import evaluationReducer from './te/evaluationReducer';
import videosByPerformerReducer from './te/videosByPerformerReducer';
import videosBySongReducer from './te/videosBySongReducer';
import performersReducer from './te/performersReducer';
import programTypesReducer from './sys/programTypesReducer';
import programMenuReducer from './sys/programMenuReducer';
import programsReducer from './sys/programsReducer';
import groupPermissionReducer from './sys/groupPermissionReducer';
import userPermissionReducer from './sys/userPermissionReducer';
import serviceUsageReducer from './serviceUsage/serviceUsageReducer';
import menuReducer from './menu/menuReducer';
import lessonReducer from './te/lessonReducer';
import videoTypesReducer from './video/videoTypesReducer';
import videoReducer from './video/videoReducer';
import giftsReducer from './gift/giftsReducer';
import healthReducer from './event/healthReducer';
import capApplyReducer from './cap/capApplyReducer';
import giftLabelReducer from './gift/giftLabelReducer';
import recommendReducer from './te/recommendReducer';
import crewOnlineEventReducer from './crew/crewOnlineEventReducer';
import freeTalkTopicReducer from './te/freeTalkTopicReducer';
import freeTalkSettingReducer from './te/freeTalkSettingReducer';
import appVersionReducer from './app/appVersionReducer';
import appBannerReducer from './app/appBannerReducer';
import crewSeatComparisonReducer from './te/crewSeatComparisonReducer';
import capModifyReducer from './cap/capModifyReducer';
import capLabelReducer from './cap/capLabelPrintReducer';
import dailyLogReducer from './te/dailyLogReducer';
import crewLevelReducer from './te/crewLevelReducer';
import nameListReducer from './sms/nameListReducer';
import eventListReducer from './sms/eventListReducer';
import supervisorSettingReducer from './te/supervisorSettingReducer';
import taxInfoReducer from './event/taxInfoReducer';
import extraFeeReducer from './event/extraFeeReducer';
import eventWordReducer from './event/eventWordReducer';
import appEventBannerReducer from './app/appEventBannerReducer';
import regionEventBannerReducer from './app/regionEventBannerReducer';
import shippingDetailReducer from './mailOrder/shippingDetailReducer';
import eventVenueReducer from './event/eventVenueReducer';
import crewListReducer from './crew/crewListReducer';
import crewPointsValueReducer from './crew/crewPointsValueReducer';
import eventNoticeReducer from './event/eventNoticeReducer';
import crewPointsReducer from './crew/crewPointsReducer';
import appHomeImageReducer from './app/appHomeImageReducer';
import crossMarketUserReducer from './sys/crossMarketUserReducer';
import appImportantNoticeReducer from './app/appImportantNoticeReducer';
import previewDocReducer from './event/eventPreviewDocReducer';
import crewPointLockReducer from './crew/crewPointLockReducer';
import eventPropReducer from './event/eventpropReducer';

const rootReducer = combineReducers({
  capItemsState: capItemRelationshipReducer,
  capCheckingState: capCheckingReducer,
  capApplyState: capApplyReducer,
  evaluationState: evaluationReducer,
  performersState: performersReducer,
  videosByPerformerState: videosByPerformerReducer,
  videosBySongState: videosBySongReducer,
  programTypesState: programTypesReducer,
  programMenuState: programMenuReducer,
  programsState: programsReducer,
  groupPermissionState: groupPermissionReducer,
  userPermissionState: userPermissionReducer,
  serviceUsageState: serviceUsageReducer,
  menuState: menuReducer,
  lessonState: lessonReducer,
  videoTypesState: videoTypesReducer,
  videoState: videoReducer,
  giftsState: giftsReducer,
  healthState: healthReducer,
  giftLabelState: giftLabelReducer,
  recommendState: recommendReducer,
  crewOnlineEventState: crewOnlineEventReducer,
  freeTalkTopicState: freeTalkTopicReducer,
  freeTalkSettingState: freeTalkSettingReducer,
  appVersionState: appVersionReducer,
  appBannerState: appBannerReducer,
  crewSeatComparisonState: crewSeatComparisonReducer,
  capModifyState: capModifyReducer,
  capLabelState: capLabelReducer,
  dailyLogState: dailyLogReducer,
  crewLevelState: crewLevelReducer,
  nameListState: nameListReducer,
  eventListState: eventListReducer,
  supervisorSettingState: supervisorSettingReducer,
  taxInfoState: taxInfoReducer,
  extraFeeState: extraFeeReducer,
  eventWordState: eventWordReducer,
  appEventBannerState: appEventBannerReducer,
  eventPropState: eventPropReducer,
  regionEventBannerState: regionEventBannerReducer,
  shippingDetailState: shippingDetailReducer,
  eventVenueState: eventVenueReducer,
  crewListState: crewListReducer,
  crewPointsValueState: crewPointsValueReducer,
  eventNoticeState: eventNoticeReducer,
  crewPointsState: crewPointsReducer,
  appHomeImageState: appHomeImageReducer,
  crossMarketUserState: crossMarketUserReducer,
  appImportantNoticeState: appImportantNoticeReducer,
  previewDocState: previewDocReducer,
  crewPointLockState: crewPointLockReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

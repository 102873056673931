import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/healthActions';

interface HealthState {
  isLoading: boolean;
  error: string | null;
  healthList: {
    actNo: string;
    memNo: string;
    phone: string;
    leagueCount: string;
    adult1: string;
    adult2: string;
    child1: string;
    child2: string;
    child3: string;
    child4: string;
    allSeat: string;
    udt: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  healthList: [],
};

const healthReducer = (state: HealthState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_HEALTH_DECLARATION_LIST_START:
      return {
        isLoading: true,
        error: null,
        healthList: state.healthList,
      };
    case ActionType.FETCH_HEALTH_DECLARATION_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        healthList: action.payload,
      };
    case ActionType.FETCH_HEALTH_DECLARATION_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        healthList: state.healthList,
      };
    case ActionType.DL_HEALTH_DECLARATION_LIST_START:
      return {
        isLoading: true,
        error: null,
        healthList: state.healthList,
      };
    case ActionType.DL_HEALTH_DECLARATION_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        healthList: state.healthList,
      };
    case ActionType.DL_HEALTH_DECLARATION_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        healthList: state.healthList,
      };
    default:
      return state;
  }
};

export default healthReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/crewLevelActions';

interface CrewLevelState {
  isLoading: boolean;
  error: string | null;
  crewList: {
    crewNo: string;
    crewName: string;
  }[];
  levelList: {
    mainTypeCode: number;
    mainType: string;
    contents: {
      subType: string;
      level: number;
    }[];
  }[];
  levelDetail: {
    mainTypeCode: number;
    mainType: string;
    subType: string;
    details: {
      songNo: string;
      songName: string;
      isPassed: boolean;
    }[];
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  crewList: [],
  levelList: [],
  levelDetail: null,
};

const crewLevelReducer = (
  state: CrewLevelState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_TE_CREW_LIST_START:
      return {
        isLoading: true,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewList: action.payload,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_LIST_START:
      return {
        isLoading: true,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewList: state.crewList,
        levelList: action.payload,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_DETAIL_START:
      return {
        isLoading: true,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_DETAIL_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: action.payload,
      };
    case ActionType.FETCH_TE_CREW_LEVEL_DETAIL_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.LESSON_PASS_START:
    case ActionType.LESSON_NOT_PASS_START:
    case ActionType.LESSONS_ALL_PASS_START:
    case ActionType.LESSONS_ALL_NOT_PASS_START:
      return {
        isLoading: true,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.LESSON_PASS_SUCCESS:
    case ActionType.LESSON_NOT_PASS_SUCCESS:
    case ActionType.LESSONS_ALL_PASS_SUCCESS:
    case ActionType.LESSONS_ALL_NOT_PASS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    case ActionType.LESSON_PASS_ERROR:
    case ActionType.LESSON_NOT_PASS_ERROR:
    case ActionType.LESSONS_ALL_PASS_ERROR:
    case ActionType.LESSONS_ALL_NOT_PASS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewList: state.crewList,
        levelList: state.levelList,
        levelDetail: state.levelDetail,
      };
    default:
      return state;
  }
};

export default crewLevelReducer;

import { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// const Home = lazy(() => import('../pages/dashboard/Home'));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const TEMonthlyReport = lazy(
  () => import("../pages/dashboard/TEMonthlyReport")
);
const ServiceUsage = lazy(() => import("../pages/serviceUsage/ServiceUsage"));
const PointsGiftMaintain = lazy(
  () => import("../pages/giftPoints/PointsGiftMaintain")
);
const PointsGiftMaintainCreate = lazy(
  () => import("../pages/giftPoints/PointsGiftMaintainCreate")
);
const PointsGiftMaintainUpdate = lazy(
  () => import("../pages/giftPoints/PointsGiftMaintainUpdate")
);
const GiftLabel = lazy(() => import("../pages/giftPoints/GiftLabel"));
const MainVideoType = lazy(() => import("../pages/video/MainVideoType"));
const MainVideoTypeCreate = lazy(
  () => import("../pages/video/MainVideoTypeCreate")
);
const MainVideoTypeUpdate = lazy(
  () => import("../pages/video/MainVideoTypeUpdate")
);
const SubVideoType = lazy(() => import("../pages/video/SubVideoType"));
const SubVideoTypeCreate = lazy(
  () => import("../pages/video/SubVideoTypeCreate")
);
const SubVideoTypeUpdate = lazy(
  () => import("../pages/video/SubVideoTypeUpdate")
);
const VideoInfoCreate = lazy(() => import("../pages/video/VideoInfoCreate"));
const VideoInfo = lazy(() => import("../pages/video/VideoInfo"));
const VideoInfoUpdate = lazy(() => import("../pages/video/VideoInfoUpdate"));
const InternalList = lazy(() => import("../pages/sms/InternalList"));
const SendSms = lazy(() => import("../pages/sms/SendSms"));
const SmsEvent = lazy(() => import("../pages/sms/SmsEvent"));
const Evaluation = lazy(() => import("../pages/te/Evaluation"));
const Performer = lazy(() => import("../pages/te/Performer"));
const PerformerCreate = lazy(() => import("../pages/te/PerformerCreate"));
const PerformerUpdate = lazy(() => import("../pages/te/PerformerUpdate"));
const FaceCallByPerformer = lazy(
  () => import("../pages/te/FaceCallByPerformer")
);
const FaceCallBySong = lazy(() => import("../pages/te/FaceCallBySong"));
const Lesson = lazy(() => import("../pages/te/Lesson"));
const LessonUpdate = lazy(() => import("../pages/te/LessonUpdate"));
const Holiday = lazy(() => import("../pages/te/Holiday"));
const CrewCountCreating = lazy(() => import("../pages/te/CrewCountCreating"));
const CrewLevel = lazy(() => import("../pages/te/CrewLevel"));
const Supervisor = lazy(() => import("../pages/te/Supervisor"));
const CrewSchedule = lazy(() => import("../pages/te/CrewSchedule"));
const TeModify = lazy(() => import("../pages/te/TeModify"));
const FreeTalkTopic = lazy(() => import("../pages/te/FreeTalkTopic"));
const FreeTalkTopicCreate = lazy(
  () => import("../pages/te/FreeTalkTopicCreate")
);
const FreeTalkTopicUpdate = lazy(
  () => import("../pages/te/FreeTalkTopicUpdate")
);
const MonthlyFreeTalk = lazy(() => import("../pages/te/MonthlyFreeTalk"));
const MonthlyFreeTalkUpdate = lazy(
  () => import("../pages/te/MonthlyFreeTalkUpdate")
);
const Daily = lazy(() => import("../pages/te/report/Daily"));
const Monthly = lazy(() => import("../pages/te/report/Monthly"));
const Service = lazy(() => import("../pages/te/report/Service"));
const TopTe = lazy(() => import("../pages/te/report/TopTe"));
const SuperKid = lazy(() => import("../pages/te/report/SuperKid"));
const TeSchedule = lazy(() => import("../pages/te/report/TeSchedule"));
const TeTracking = lazy(() => import("../pages/te/report/TeTracking"));
const CrewSeatComparison = lazy(() => import("../pages/te/CrewSeatComparison"));
const CrewSeatComparisonCreate = lazy(
  () => import("../pages/te/CrewSeatComparisonCreate")
);
const CrewSeatComparisonUpdate = lazy(
  () => import("../pages/te/CrewSeatComparisonUpdate")
);
const MonthlyRecommend = lazy(() => import("../pages/te/MonthlyRecommend"));
const MonthlyRecommendUpdate = lazy(
  () => import("../pages/te/MonthlyRecommendUpdate")
);
const DailyLog = lazy(() => import("../pages/te/report/DailyLog"));
const ItemRelationship = lazy(() => import("../pages/cap/ItemRelationship"));
const CapChecking = lazy(() => import("../pages/cap/CapChecking"));
const CapApply = lazy(() => import("../pages/cap/CapApply"));
const CapProcess = lazy(() => import("../pages/cap/CapProcess"));
const CapQuery = lazy(() => import("../pages/cap/CapQuery"));
const CapHatLabelPrint = lazy(() => import("../pages/cap/CapHatLabelPrint"));
const CapModify = lazy(() => import("../pages/cap/CapModify"));
const GroupPermission = lazy(() => import("../pages/sys/GroupPermission"));
const GroupPermissionCreate = lazy(
  () => import("../pages/sys/GroupPermissionCreate")
);
const GroupPermissionUpdate = lazy(
  () => import("../pages/sys/GroupPermissionUpdate")
);
const UserPermission = lazy(() => import("../pages/sys/UserPermission"));
const UserPermissionCreate = lazy(
  () => import("../pages/sys/UserPermissionCreate")
);
const UserPermissionUpdate = lazy(
  () => import("../pages/sys/UserPermissionUpdate")
);
const ProgramTypes = lazy(() => import("../pages/sys/ProgramTypes"));
const ProgramTypesCreate = lazy(
  () => import("../pages/sys/ProgramTypesCreate")
);
const ProgramTypesUpdate = lazy(
  () => import("../pages/sys/ProgramTypesUpdate")
);
const ProgramMenu = lazy(() => import("../pages/sys/ProgramMenu"));
const ProgramMenuCreate = lazy(() => import("../pages/sys/ProgramMenuCreate"));
const ProgramMenuUpdate = lazy(() => import("../pages/sys/ProgramMenuUpdate"));
const Program = lazy(() => import("../pages/sys/Program"));
const ProgramCreate = lazy(() => import("../pages/sys/ProgramCreate"));
const ProgramUpdate = lazy(() => import("../pages/sys/ProgramUpdate"));
const CrossMarketUser = lazy(() => import("../pages/sys/CrossMarketUser"));
const EventInformation = lazy(() => import("../pages/event/EventInformation"));
const EventAdditional = lazy(() => import("../pages/event/EventAdditional"));
const EventVenue = lazy(() => import("../pages/event/EventVenue"));
const EventVenueCreate = lazy(() => import("../pages/event/EventVenueCreate"));
const EventCheckIn = lazy(() => import("../pages/event/EventCheckIn"));
const EventNewMember = lazy(() => import("../pages/event/EventNewMember"));
const EventSummerCamp = lazy(() => import("../pages/event/EventSummerCamp"));
const EventSpecial = lazy(() => import("../pages/event/EventSpecial"));
const EventEcStaffImport = lazy(
  () => import("../pages/event/EventEcStaffImport")
);
const EventTopic = lazy(() => import("../pages/event/EventTopic"));
const EventTopicCreate = lazy(() => import("../pages/event/EventTopicCreate"));
const EventSessions = lazy(() => import("../pages/event/EventSessions"));
const EventExtraFees = lazy(() => import("../pages/event/EventExtraFees"));
const EventExtraFeesMaintain = lazy(
  () => import("../pages/event/EventExtraFeesMaintain")
);
const EventWords = lazy(() => import("../pages/event/EventWords"));
const EventProps = lazy(() => import("../pages/event/EventProps"));
const AmusementTax = lazy(() => import("../pages/event/AmusementTax"));
const EventPreviewDoc = lazy(() => import("../pages/event/EventPreviewDoc"));
const EventTicket = lazy(() => import("../pages/event/report/EventTicket"));
const EventPreview = lazy(() => import("../pages/event/report/EventPreview"));
const EventEcSeatList = lazy(
  () => import("../pages/event/report/EventEcSeatList")
);
const EventPreviewVerify = lazy(
  () => import("../pages/event/report/EventPreviewVerify")
);
const EventCredit = lazy(() => import("../pages/event/report/EventCredit"));
const EventAssurance = lazy(
  () => import("../pages/event/report/EventAssurance")
);
const FreelancerScheduling = lazy(
  () => import("../pages/event/report/FreelancerScheduling")
);
const CrewArrangement = lazy(
  () => import("../pages/event/report/CrewArrangement")
);
const CrewAdjustment = lazy(
  () => import("../pages/event/report/CrewAdjustment")
);
const CrewPlanning = lazy(() => import("../pages/event/report/CrewPlanning"));
const ActivityPayment = lazy(
  () => import("../pages/event/report/ActivityPayment")
);
const EventRegistered = lazy(
  () => import("../pages/event/report/EventRegistered")
);
const EventList = lazy(() => import("../pages/event/query/EventList"));
const Health = lazy(() => import("../pages/event/query/Health"));
const EventTypes = lazy(() => import("../pages/event/EventTypes"));
const EventFees = lazy(() => import("../pages/event/EventFees"));
const EventNotice = lazy(() => import("../pages/event/EventNotice"));
const Seat = lazy(() => import("../pages/ecSeat/Seat"));
const SeatStatus = lazy(() => import("../pages/ecSeat/SeatStatus"));
const CrewType = lazy(() => import("../pages/crew/CrewType"));
const CrewList = lazy(() => import("../pages/crew/CrewList"));
const CrewPointValue = lazy(() => import("../pages/crew/CrewPointValue"));
const CrewPoint = lazy(() => import("../pages/crew/CrewPoint"));
const CrewInfo = lazy(() => import("../pages/crew/CrewInfo"));
const DateControl = lazy(() => import("../pages/crew/DateControl"));
const CrewActivity = lazy(() => import("../pages/crew/CrewActivity"));
const CrewOnlineEvent = lazy(() => import("../pages/crew/CrewOnlineEvent"));
const CrewActivityReport = lazy(
  () => import("../pages/crew/report/CrewActivity")
);
const CrewRequest = lazy(() => import("../pages/crew/report/CrewRequest"));
const CrewPoints = lazy(() => import("../pages/crew/report/CrewPoints"));
const CheckInQuery = lazy(() => import("../pages/checkIn/query/CheckIn"));
const SurveyItem = lazy(() => import("../pages/survey/SurveyItem"));
const SurveyModel = lazy(() => import("../pages/survey/SurveyModel"));
const SurveyActivity = lazy(() => import("../pages/survey/SurveyActivity"));
const SurveyScan = lazy(() => import("../pages/survey/SurveyScan"));
const SurveyReport = lazy(() => import("../pages/survey/report/Survey"));
const SurveyCommand = lazy(() => import("../pages/survey/query/SurveyCommand"));
const MailOrder = lazy(() => import("../pages/mailOrder/MailOrder"));
const AgingReport = lazy(() => import("../pages/membershipReport/Aging"));
const ActiveMemberReport = lazy(
  () => import("../pages/membershipReport/ActiveMember")
);
const ServiceAnalyzeReport = lazy(
  () => import("../pages/membershipReport/ServiceAnalyze")
);
const AppServiceUsage = lazy(
  () => import("../pages/membershipReport/AppServiceUsage")
);
const AppFeedback = lazy(() => import("../pages/app/AppFeedback"));
const AppReleaseVersion = lazy(() => import("../pages/app/AppReleaseVersion"));
const AppBanner = lazy(() => import("../pages/app/AppBanner"));
const AppBannerCreate = lazy(() => import("../pages/app/AppBannerCreate"));
const AppBannerUpdate = lazy(() => import("../pages/app/AppBannerUpdate"));
const AppEventBanner = lazy(() => import("../pages/app/AppEventBanner"));
const RegionEventBanner = lazy(() => import("../pages/app/RegionEventBanner"));
const AppHomeBackground = lazy(() => import("../pages/app/AppHomeBackground"));
const AppImportantNotice = lazy(
  () => import("../pages/app/AppImportantNotice")
);
const WfcGeneral = lazy(() => import("../pages/dashboard/WfcGeneral"));
const WfcCAP = lazy(() => import("../pages/dashboard/WfcCAP"));

const Routes = () => {
  const allRoutes = [
    // { path: '/', component: Dashboard },
    {
      path: "/dashboard/report/membership-pbi",
      component: Dashboard,
    },
    {
      path: "/dashboard/report/service-usage-pbi",
      component: ServiceUsage,
    },
    {
      path: "/dashboard/report/feedback",
      component: AppFeedback,
    },
    {
      path: "/dashboard/report/service-analyze",
      component: ServiceAnalyzeReport,
    },
    {
      path: "/dashboard/report/te-monthly",
      component: TEMonthlyReport,
    },
    {
      path: "/dashboard/report/app-service-usage",
      component: AppServiceUsage,
    },
    {
      path: "/dashboard/report/wfc-general",
      component: WfcGeneral,
    },
    {
      path: "/dashboard/report/wfc-cap",
      component: WfcCAP,
    },
    {
      path: "/gift-points/maintain/points-gift-maintain",
      component: PointsGiftMaintain,
    },
    {
      path: "/gift-points/maintain/points-gift-maintain/create",
      component: PointsGiftMaintainCreate,
    },
    {
      path: "/gift-points/maintain/points-gift-maintain/:giftNo",
      component: PointsGiftMaintainUpdate,
    },
    {
      path: "/gift-points/maintain/gift-label",
      component: GiftLabel,
    },
    {
      path: "/video/setting/main-video-type",
      component: MainVideoType,
    },
    {
      path: "/video/setting/main-video-type/create",
      component: MainVideoTypeCreate,
    },
    {
      path: "/video/setting/main-video-type/:id",
      component: MainVideoTypeUpdate,
    },
    {
      path: "/video/setting/sub-video-type",
      component: SubVideoType,
    },
    {
      path: "/video/setting/sub-video-type/create",
      component: SubVideoTypeCreate,
    },
    {
      path: "/video/setting/sub-video-type/:mainType/:subType",
      component: SubVideoTypeUpdate,
    },
    {
      path: "/video/setting/video-info",
      component: VideoInfo,
    },
    {
      path: "/video/setting/video-info/create",
      component: VideoInfoCreate,
    },
    {
      path: "/video/setting/video-info/:seq",
      component: VideoInfoUpdate,
    },
    {
      path: "/sms/setting/internal-name-list",
      component: InternalList,
    },
    {
      path: "/sms/maintain/send-sms",
      component: SendSms,
    },
    {
      path: "/sms/maintain/event-sms",
      component: SmsEvent,
    },
    {
      path: "/telephone-english/setting/evaluation",
      component: Evaluation,
    },
    {
      path: "/telephone-english/setting/performer",
      component: Performer,
    },
    {
      path: "/telephone-english/setting/performer/create",
      component: PerformerCreate,
    },
    {
      path: "/telephone-english/setting/performer/:id",
      component: PerformerUpdate,
    },
    {
      path: "/telephone-english/setting/lesson",
      component: Lesson,
    },
    {
      path: "/telephone-english/setting/lesson/:id",
      component: LessonUpdate,
    },
    {
      path: "/telephone-english/setting/holiday",
      component: Holiday,
    },
    {
      path: "/telephone-english/setting/crew-count-creating",
      component: CrewCountCreating,
    },
    {
      path: "/telephone-english/setting/crew-level",
      component: CrewLevel,
    },
    {
      path: "/telephone-english/setting/supervisor",
      component: Supervisor,
    },
    {
      path: "/telephone-english/setting/schedule",
      component: CrewSchedule,
    },
    {
      path: "/telephone-english/setting/free-conversation",
      component: FreeTalkTopic,
    },
    {
      path: "/telephone-english/setting/free-conversation/create",
      component: FreeTalkTopicCreate,
    },
    {
      path: "/telephone-english/setting/free-conversation/:seq",
      component: FreeTalkTopicUpdate,
    },
    {
      path: "/telephone-english/maintain/monthly-recommend",
      component: MonthlyRecommend,
    },
    {
      path: "/telephone-english/maintain/monthly-recommend/:year/:month",
      component: MonthlyRecommendUpdate,
    },
    {
      path: "/telephone-english/maintain/monthly-free-conversation",
      component: MonthlyFreeTalk,
    },
    {
      path: "/telephone-english/maintain/monthly-free-conversation/:year/:month",
      component: MonthlyFreeTalkUpdate,
    },
    {
      path: "/telephone-english/maintain/face-call-by-performer",
      component: FaceCallByPerformer,
    },
    {
      path: "/telephone-english/maintain/face-call-by-song",
      component: FaceCallBySong,
    },
    {
      path: "/telephone-english/maintain/te-modify",
      component: TeModify,
    },
    {
      path: "/telephone-english/maintain/seat-comparison",
      component: CrewSeatComparison,
    },
    {
      path: "/telephone-english/maintain/seat-comparison/create",
      component: CrewSeatComparisonCreate,
    },
    {
      path: "/telephone-english/maintain/seat-comparison/:id",
      component: CrewSeatComparisonUpdate,
    },
    {
      path: "/telephone-english/report/daily-log",
      component: DailyLog,
    },
    {
      path: "/telephone-english/report/daily",
      component: Daily,
    },
    {
      path: "/telephone-english/report/monthly",
      component: Monthly,
    },
    {
      path: "/telephone-english/report/service",
      component: Service,
    },
    {
      path: "/telephone-english/report/top-te",
      component: TopTe,
    },
    {
      path: "/telephone-english/report/super-kid",
      component: SuperKid,
    },
    {
      path: "/telephone-english/report/te-schedule",
      component: TeSchedule,
    },
    {
      path: "/telephone-english/report/te-tracking",
      component: TeTracking,
    },
    {
      path: "/cap/setting/item-relationship",
      component: ItemRelationship,
    },
    {
      path: "/cap/maintain/cap-checking",
      component: CapChecking,
    },
    {
      path: "/cap/maintain/cap-apply",
      component: CapApply,
    },
    {
      path: "/cap/maintain/cap-process",
      component: CapProcess,
    },
    {
      path: "/cap/maintain/hat-label-print",
      component: CapHatLabelPrint,
    },
    {
      path: "/cap/maintain/cap-modify",
      component: CapModify,
    },
    {
      path: "/cap/query/cap-query",
      component: CapQuery,
    },
    {
      path: "/system-permission/setting/group-permission",
      component: GroupPermission,
    },
    {
      path: "/system-permission/setting/group-permission/create",
      component: GroupPermissionCreate,
    },
    {
      path: "/system-permission/setting/group-permission/:id",
      component: GroupPermissionUpdate,
    },
    {
      path: "/system-permission/setting/user-permission",
      component: UserPermission,
    },
    {
      path: "/system-permission/setting/user-permission/create",
      component: UserPermissionCreate,
    },
    {
      path: "/system-permission/setting/user-permission/:id",
      component: UserPermissionUpdate,
    },
    {
      path: "/system-permission/setting/program-types",
      component: ProgramTypes,
    },
    {
      path: "/system-permission/setting/program-types/create",
      component: ProgramTypesCreate,
    },
    {
      path: "/system-permission/setting/program-types/:id",
      component: ProgramTypesUpdate,
    },
    {
      path: "/system-permission/setting/program-menu",
      component: ProgramMenu,
    },
    {
      path: "/system-permission/setting/program-menu/create",
      component: ProgramMenuCreate,
    },
    {
      path: "/system-permission/setting/program-menu/:id",
      component: ProgramMenuUpdate,
    },
    {
      path: "/system-permission/setting/program",
      component: Program,
    },
    {
      path: "/system-permission/setting/program/create",
      component: ProgramCreate,
    },
    {
      path: "/system-permission/setting/program/:id",
      component: ProgramUpdate,
    },
    {
      path: "/system-permission/setting/cross-market-user",
      component: CrossMarketUser,
    },
    {
      path: "/event/setting/event-types",
      component: EventTypes,
    },
    {
      path: "/event/setting/event-fees",
      component: EventFees,
    },
    {
      path: "/event/setting/event-notice",
      component: EventNotice,
    },
    {
      path: "/event/setting/event-information",
      component: EventInformation,
    },
    {
      path: "/event/setting/event-additional",
      component: EventAdditional,
    },
    {
      path: "/event/setting/event-venue",
      component: EventVenue,
    },
    {
      path: "/event/setting/event-venue/create",
      component: EventVenueCreate,
    },
    {
      path: "/event/setting/event-words",
      component: EventWords,
    },
    {
      path: "/event/setting/event-props",
      component: EventProps,
    },
    {
      path: "/event/setting/amusement-tax",
      component: AmusementTax,
    },
    {
      path: "/event/maintain/event-check-in",
      component: EventCheckIn,
    },
    {
      path: "/event/maintain/event-new-member",
      component: EventNewMember,
    },
    {
      path: "/event/maintain/summer-camp",
      component: EventSummerCamp,
    },
    {
      path: "/event/maintain/event-special",
      component: EventSpecial,
    },
    {
      path: "/event/maintain/ec-staff-import",
      component: EventEcStaffImport,
    },
    {
      path: "/event/maintain/topic",
      component: EventTopic,
    },
    {
      path: "/event/maintain/topic/create",
      component: EventTopicCreate,
    },
    {
      path: "/event/maintain/session",
      component: EventSessions,
    },
    {
      path: "/event/maintain/extra-fees",
      component: EventExtraFees,
    },
    {
      path: "/event/maintain/extra-fees-maintain",
      component: EventExtraFeesMaintain,
    },
    {
      path: "/event/maintain/preview-document",
      component: EventPreviewDoc,
    },
    {
      path: "/event/report/event-ticket",
      component: EventTicket,
    },
    {
      path: "/event/report/event-preview",
      component: EventPreview,
    },
    {
      path: "/event/report/ec-seat-list",
      component: EventEcSeatList,
    },
    {
      path: "/event/report/event-preview-verify",
      component: EventPreviewVerify,
    },
    {
      path: "/event/report/event-credit",
      component: EventCredit,
    },
    {
      path: "/event/report/event-assurance",
      component: EventAssurance,
    },
    {
      path: "/event/report/freelancer-scheduling",
      component: FreelancerScheduling,
    },
    {
      path: "/event/report/crew-arrangement",
      component: CrewArrangement,
    },
    {
      path: "/event/report/crew-adjustment",
      component: CrewAdjustment,
    },
    {
      path: "/event/report/crew-planning",
      component: CrewPlanning,
    },
    {
      path: "/event/report/activity-payment",
      component: ActivityPayment,
    },
    {
      path: "/event/report/event-registered",
      component: EventRegistered,
    },
    {
      path: "/event/query/event-list",
      component: EventList,
    },
    {
      path: "/event/query/health",
      component: Health,
    },
    {
      path: "/event/query/check-in",
      component: CheckInQuery,
    },
    {
      path: "/event/setting/ec-seat",
      component: Seat,
    },
    {
      path: "/event/setting/ec-seat-status",
      component: SeatStatus,
    },
    {
      path: "/crew/setting/crew-type",
      component: CrewType,
    },
    {
      path: "/crew/setting/crew-list",
      component: CrewList,
    },
    {
      path: "/crew/setting/information",
      component: CrewInfo,
    },
    {
      path: "/crew/maintain/point-value",
      component: CrewPointValue,
    },
    {
      path: "/crew/maintain/point",
      component: CrewPoint,
    },
    {
      path: "/crew/maintain/date-control",
      component: DateControl,
    },
    {
      path: "/crew/maintain/crew-activity",
      component: CrewActivity,
    },
    {
      path: "/crew/maintain/online-event",
      component: CrewOnlineEvent,
    },
    {
      path: "/crew/report/crew-activity",
      component: CrewActivityReport,
    },
    {
      path: "/crew/report/crew-request",
      component: CrewRequest,
    },
    {
      path: "/crew/report/crew-points",
      component: CrewPoints,
    },
    {
      path: "/survey/setting/survey-item",
      component: SurveyItem,
    },
    {
      path: "/survey/setting/survey-model",
      component: SurveyModel,
    },
    {
      path: "/survey/setting/survey-activity",
      component: SurveyActivity,
    },
    {
      path: "/survey/setting/survey-scan",
      component: SurveyScan,
    },
    {
      path: "/survey/report/survey",
      component: SurveyReport,
    },
    {
      path: "/survey/query/command",
      component: SurveyCommand,
    },
    {
      path: "/mail-order/report/shipping-details",
      component: MailOrder,
    },
    {
      path: "/membership-report/report/aging",
      component: AgingReport,
    },
    {
      path: "/membership-report/report/active-member",
      component: ActiveMemberReport,
    },
    {
      path: "/app/maintain/release-version",
      component: AppReleaseVersion,
    },
    {
      path: "/app/maintain/banner",
      component: AppBanner,
    },
    {
      path: "/app/maintain/banner/create",
      component: AppBannerCreate,
    },
    {
      path: "/app/maintain/banner/:seq",
      component: AppBannerUpdate,
    },
    {
      path: "/app/maintain/event-banner",
      component: AppEventBanner,
    },
    {
      path: "/app/maintain/region-event-banner",
      component: RegionEventBanner,
    },
    {
      path: "/app/maintain/home-background",
      component: AppHomeBackground,
    },
    {
      path: "/app/maintain/important-notice",
      component: AppImportantNotice,
    },
  ];

  return (
    <Switch>
      {allRoutes.map((route, i) => (
        <Route exact path={route.path} component={route.component} key={i} />
      ))}
      <Redirect from="/" to="/dashboard/report/membership-pbi" />
    </Switch>
  );
};

export default Routes;

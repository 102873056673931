import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/cap/capItemRelationshipActions';

interface CapItemsState {
  isLoading: boolean;
  error: string | null;
  capItemRelationships: {
    capNo: string;
    capLessonItems: {
      capItemNo: number;
      capItemName: string;
      capItemType: string;
      verifyMsg_Tw: string;
      verifyMsg_En: string;
      capLessonSongs: {
        capLessonItemNo: number;
        songNo: string;
        songName: string;
        newSongName: string;
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  capItemRelationships: [],
};

const capItemRelationshipReducer = (
  state: CapItemsState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CAP_ITEMS_START:
      return {
        isLoading: true,
        error: null,
        capItemRelationships: state.capItemRelationships,
      };
    case ActionType.FETCH_CAP_ITEMS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capItemRelationships: action.payload,
      };
    case ActionType.FETCH_CAP_ITEMS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capItemRelationships: state.capItemRelationships,
      };
    case ActionType.UPDATE_CAP_ITEM_VERIFY_MSG_START:
      return {
        isLoading: true,
        error: null,
        capItemRelationships: state.capItemRelationships,
      };
    case ActionType.UPDATE_CAP_ITEM_VERIFY_MSG_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capItemRelationships: state.capItemRelationships,
      };
    case ActionType.UPDATE_CAP_ITEM_VERIFY_MSG_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capItemRelationships: state.capItemRelationships,
      };
    default:
      return state;
  }
};

export default capItemRelationshipReducer;

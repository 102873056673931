const en = {
  "app.signIn": "Sign in with your WFE Microsoft account",
  "app.signOut": "Sign out",
  "app.giftPoints": "Learning Points",
  "app.video": "Video",
  "app.sms": "SMS and Push Notification",
  "app.TE": "Telephone English",
  "app.cap": "CAP",
  "app.gpMaintain": "Gift Points Maintain",
  "app.mainVideoType": "Main Video Type",
  "app.subVideoType": "Sub Video Type",
  "app.videoInfo": "Video Information",
  "app.sendSms": "Club News",
  "app.evaluation": "Evaluation",
  "app.faceCallPerformer": "Face Call Performer",
  "app.byPerformer": "Face Call by Performer",
  "app.bySong": "Face Call by Song",
  "app.capItemRelationship": "CAP List",
  "app.capChecking": "CAP Checking",
  "app.mainMenu": "Main Menu",
  "app.inStocks": "In Stocks",
  "app.outOfStocks": "Out of Stocks",
  "app.createNew": "Create New",
  "app.giftNo": "Gift Number",
  "app.image": "Image",
  "app.giftName": "Gift Name",
  "app.price": "Price",
  "app.qty": "Quantity",
  "app.points": "Points",
  "app.createDate": "Create Date",
  "app.action": "Action",
  "app.createNewGift": "Create New Gift",
  "app.upload": "Add Image File",
  "app.uploadImage": "Upload Image",
  "app.giftDescription": "Description",
  "app.giftSpec": "Spec",
  "app.updateGift": "Update Gift",
  "app.submit": "Submit",
  "app.cancel": "Cancel",
  "app.videoTypeName": "Type Name",
  "app.createNewMainVideoType": "Create New Main Video Type",
  "app.updateMainVideoType": "Update Main Video Type",
  "app.selectMainVideoType": "Main Video Type",
  "app.selectSubVideoType": "Sub Video Type",
  "app.mainVideoTypeName": "Main Video Type",
  "app.subVideoTypeName": "Sub Video Type",
  "app.createNewSubVideoType": "Create New Sub Video Type",
  "app.currentSubTypes": "Current Sub Types",
  "app.addInputField": "Add Input Field",
  "app.updateSubVideoType": "Update Sub Video Type",
  "app.seq": "Seq",
  "app.title": "Title",
  "app.specialView": "Special View",
  "app.openDate": "Open Date",
  "app.disabled": "Disabled",
  "app.createNewVideo": "Create New Video",
  "app.videoUrl": "Video URL{type}",
  "app.videoDescription": "Description",
  "app.updateVideo": "Update Video",
  "app.targets": "Targets",
  "app.sendSingle": "Send to Single Member",
  "app.sendAll": "Send to All Members",
  "app.sendSome": "Send to Specific Members",
  "app.phoneNo": "Member Number",
  "app.smsMsg": "Message",
  "app.performerName": "Performer Name",
  "app.country": "Country",
  "app.pStartDate": "On Board",
  "app.pResignDate": "Resign",
  "app.createNewPerformer": "Create New Performer",
  "app.updatePerformer": "Update Performer",
  "app.selectPerformer": "Select Performer",
  "app.videoList": "Video List",
  "app.addNewVideo": "Add New Video",
  "app.songName": "Song Name",
  "app.onlineDate": "Online Date",
  "app.offlineDate": "Offline Date",
  "app.videoLink": "Video ID",
  "app.preview": "Preview",
  "app.selectSong": "Select Song",
  "app.performerList": "Performer List",
  "app.videoPreview": "Video Preview",
  "app.subItemNo": "Sub Item Number",
  "app.subItemName": "Sub Item Name",
  "app.newSubItemName": "New Sub Item Name",
  "app.verifyMsgTw": "Verify Message(TC)",
  "app.verifyMsgEn": "Verify Message(EN)",
  "app.applyDate": "Apply Date",
  "app.memberNo": "Member Number",
  "app.childNameTw": "Kid's Name(TC)",
  "app.childNameEn": "Kid's Name(EN)",
  "app.childBDay": "Kid's B-Day",
  "app.task": "Task",
  "app.audio": "Audio",
  "app.approve": "Approve",
  "app.playAudio": "Play Audio",
  "app.isApprove": "Approve This Task or Not",
  "app.approveStatus": "Approve Status",
  "app.reason": "Reason",
  "app.remarks": "Remarks",
  "app.approved": "Yes",
  "app.notApproved": "No",
  "app.reason1": "Failed to Play",
  "app.reason2": "Not Match with The Task",
  "app.reason3": "Others",
  "app.giftLabel": "Gift Label",
  "app.printDate": "Print Date",
  "app.exchangeDate": "Exchange Date",
  "app.memberName": "Member Name",
  "app.exchangeDetail": "Gift Detail",
  "app.dateFilter": "Filter by Print Date",
  "app.filter": "Filter",
  "app.reset": "Reset",
  "app.printPreview": "Update status and Print",
  "app.prepare": "Prepare",
  "app.printUnit": " ",
  "app.printPaper": "A4 label paper with 6 grids",
  "app.print": "Print",
  "app.singlePrintResult": "Result(Single)",
  "app.C01": "Blue",
  "app.C02": "Deep Blue",
  "app.C03": "Green",
  "app.C04": "Light Green",
  "app.C05": "Yellow",
  "app.role": "Role",
  "app.age1": "Age 0 ~ 1",
  "app.age2": "Age 1 ~ 2",
  "app.age3": "Age 2 ~ 3",
  "app.age4": "Age 3 ~ 5",
  "app.age5": "Age 5 ~ 8",
  "app.age6": "Age above 8",
  "app.ability1": "Singing",
  "app.ability2": "Pronunciation",
  "app.ability3": "Understanding",
  "app.ability4": "Speaking",
  "app.ability5": "Interactive",
  "app.updateMsg": "Update Message",
  "app.seasonOfPromo": "Holiday",
  "app.halloween": "Halloween",
  "app.xmas": "Christmas",
  "app.cNewYear": "Lunar Year",
  "app.seasonNone": "None",
  "app.monthStatistics": "Monthly Statistics",
  "app.imageUrl": "Image URL",
  "app.sysPermission": "System and Permission",
  "app.groupPermission": "Group Permission",
  "app.selectGroup": "Select Group",
  "app.settingPermission": "Permission Setting",
  "app.groupName": "Group Name",
  "app.createGroup": "Create New Group and Permission",
  "app.updateGroup": "Update Group and Permission",
  "app.userPermission": "User Permission",
  "app.userName": "User Name",
  "app.userGroup": "User Group",
  "app.createUserGroup": "Create New User Group",
  "app.updateUserGroup": "Update User Group",
  "app.memberStats": "Member Statistic",
  "app.serviceStats": "Service Statistic",
  "app.programTypes": "Program Types",
  "app.programTypeName": "Program Type Name",
  "app.sort": "Sort",
  "app.createProgramType": "Create New Program Type",
  "app.programTypeEnName": "Type Name(EN)",
  "app.programTypeTwName": "Type Name(TC)",
  "app.updateProgramType": "Update Program Type",
  "app.programMenu": "Program Menu",
  "app.programMenuEnName": "Program Menu Name(EN)",
  "app.programMenuTwName": "Program Menu Name(TC)",
  "app.createModule": "Create New Program Menu",
  "app.updateModule": "Update Program Menu",
  "app.iconName": "Program Menu Icon Name",
  "app.program": "Program",
  "app.programEnName": "Program Name(EN)",
  "app.programTwName": "Program Name(TC)",
  "app.createProgram": "Create New Program",
  "app.updateProgram": "Update Program",
  "app.programPath": "Program Path",
  "app.serviceUsage": "Service Usage PBI",
  "app.lesson": "Lesson",
  "app.lessonName": "Lesson Name",
  "app.lessonCategory": "Lesson Category",
  "app.usedInWebApp": "Active in Web/App",
  "app.usedInCTI": "Active in CTI",
  "app.updateLesson": "Update Lesson",
  "app.lessonNo": "Lesson Number",
  "app.remove": "Remove Audio",
  "app.uploadAudio": "Upload Audio",
  "app.lessonCoverImg": "Cover Image",
  "app.removeCoverImg": "Remove Cover",
  "app.uploadCover": "Upload Cover Image",
  "app.lessonDetail": "Lesson Detail",
  "app.lessonSectionNo": "Section Number",
  "app.createLessonSection": "Create New Section",
  "app.lessonSectionImg": "Section Image",
  "app.sectionContent": "Content",
  "app.updateLessonSection": "Update Section",
  "app.removeSectionImg": "Remove Section Image",
  "app.createLesson": "Create New Lesson",
  "app.next": "Next",
  "app.prev": "Previous",
  "app.lessonBasic": "Basic Information",
  "app.lessonSection": "Section Information",
  "app.create": "Add",
  "app.update": "Update",
  "app.capApply": "CAP Apply",
  "app.childName": "Child",
  "app.userCode": "Employee Code",
  "app.selectProgramMenu": "Select Program Menu: ",
  "app.selectProgramType": "Select Program Type: ",
  "app.goGoLive": "Go Go Live",
  "app.goToOld": "Go to old version",
  "app.ie": "Copy the URL below and open in Internet Explorer",
  "app.programOldPath": "Old System Program Path",
  "app.useQty": "Exchanged Qty",
  "app.adjustQty": "Adjust Qty",
  "app.EVENT": "Event",
  "app.eventInformation": "Event Information",
  "app.eventAdditional": "Event Additional",
  "app.eventVenue": "Event Venue",
  "app.eventCheckIn": "Event Check-In",
  "app.eventNewMember": "Event New Member",
  "app.summerCamp": "Summer Camp",
  "app.eventSpecial": "Event Special",
  "app.ecStaffImport": "EC Staff Import",
  "app.eventTicket": "Event Ticket Report",
  "app.eventPreview": "Event Preview Report",
  "app.ecSeatList": "EC Seat List Report",
  "app.eventPreviewVerify": "Event Preview Verify Report",
  "app.eventCredit": "Event Credit Report",
  "app.eventAssurance": "Event Assurance Report",
  "app.freelancerScheduling": "Freelancer Scheduling Report",
  "app.activityCrewArrangement": "Crew Arrangement Report",
  "app.activityCrewAdjustment": "Crew Adjustment Report",
  "app.activityCrewPlanning": "Crew Planning Report",
  "app.activityPayment": "Activity Payment Report",
  "app.eventRegistered": "Event Registered Report",
  "app.eventList": "Event List Query",
  "app.healthQuery": "Health Query",
  "app.capProcess": "CAP Process",
  "app.holiday": "Holiday",
  "app.crewCountCreating": "TE Reserve Counts",
  "app.crewLevel": "Crew Level",
  "app.supervisor": "Supervisor",
  "app.crewSchedule": "TE Schedule",
  "app.teModify": "TE Modify",
  "app.dailyReport": "Daily Report",
  "app.monthlyReport": "Monthly Report",
  "app.serviceReport": "Service Report",
  "app.topTe": "Top TE Report",
  "app.superKid": "Super Kid Report",
  "app.teSchedule": "TE Schedule Report",
  "app.teTracking": "TE Tracking Report",
  "app.ecSeatSetting": "EC Seat",
  "app.ecSeat": "EC Seats Map",
  "app.ecSeatStatus": "EC Reserved Seats",
  "app.Crew": "Crew",
  "app.crewInformation": "Crew Information",
  "app.dateControl": "Date Control",
  "app.crewActivity": "Crew Activity",
  "app.crewActivityReport": "Crew Activity Report",
  "app.crewRequestForm": "Crew Request Form",
  "app.crewPointsReport": "Crew Points Report",
  "app.checkIn": "Check In",
  "app.checkInQuery": "Check In Query",
  "app.survey": "Survey",
  "app.surveyItem": "Survey Item",
  "app.surveyModel": "Survey Model",
  "app.surveyActivity": "Survey Activity",
  "app.surveyScan": "Survey Scan",
  "app.surveyReport": "Survey Report",
  "app.surveyCommand": "Survey Command",
  "app.smsWaiting": "Event SMS",
  "app.mailOrder": "Mail Order",
  "app.mailOrderOld": "Mail Order Shipping Details",
  "app.membershipReport": "Membership Report",
  "app.agingReport": "Aging Report",
  "app.activeMemberReport": "Active Member Report",
  "app.eventNumber": "Event Number",
  "app.search": "Search",
  "app.dlExcel": "Download Excel File",
  "app.phone": "Phone Number",
  "app.participants": "Number of Participants",
  "app.adult": "Adult",
  "app.child": "Child",
  "app.smsType": "Message Type",
  "app.playVideo": "Play Video",
  "app.playMedia": "Play Media",
  "app.capCheckingNotice": "Only show 50 results in free checking tab",
  "app.searchByMemNo": "Search by member number",
  "app.applyCount": "Already apply {count} time(s) this month",
  "app.uploadMediaFile": "Upload Media File",
  "app.serviceAnalyzeReport": "Profile PBI",
  "app.childAge": "Kid's Age",
  "app.ulNotice":
    "Upload limitations: Resolution( width and height <= 300px ), Format( jpeg ), Size( <= 50KB )",
  "app.app": "APP",
  "app.feedbackReport": "Club App Survey PBI",
  "app.monthlyRecommend": "Monthly Recommend",
  "app.month": "Month",
  "app.performer": "Performer",
  "app.updateMonthlyRecommend": "Update Monthly Recommend",
  "app.year": "Year",
  "app.refFrom": "Reference from",
  "app.freeChecking": "Free checking",
  "app.familyChecking": "Family checking",
  "app.checkingStatus": "Checking status",
  "app.checkingDate": "Checking Date",
  "app.checkingRemark": "Remark",
  "app.applyRequestDate": "Apply Date",
  "app.capQuery": "CAP query",
  "app.crewOnlineEvent": "Crew Online Event",
  "app.crewName": "Crew Name",
  "app.freeTalkTopic": "Free Conversation Topic",
  "app.topic": "Topic",
  "app.questions": "Questions",
  "app.createFreeTalkTopic": "Create Free Conversation Topic",
  "app.updateFreeTalkTopic": "Update Free Conversation Topic",
  "app.monthlyFreeTalk": "Monthly Free Conversation",
  "app.monthlyFreeTalkUpdate": "Update Monthly Free Conversation",
  "app.appReleaseVersion": "Release Version",
  "app.version": "Version",
  "app.test": "Test",
  "app.release": "Release",
  "app.appBanner": "Create Home Message bar",
  "app.left": "Left",
  "app.right": "Right",
  "app.inUse": "In Use",
  "app.notUse": "Not Use",
  "app.appBannerCreate": "Create New Home Message bar",
  "app.text": "Text",
  "app.textAlign": "Text Align",
  "app.bannerLink": "Link to",
  "app.bannerNav": "Navigate To",
  "app.inUse?": "In Use",
  "app.appBannerUpdate": "Update Home Message bar",
  "app.appServiceUsage": "Service Summary",
  "app.region": "Region",
  "app.scheduleTime": "Time to Send",
  "app.scheduleTimeRemark":
    "(send it immediately when not select date and time)",
  "app.capHatLabelPrint": "Export CAP Hat Label",
  "app.teCrewSeatComparison": "Crew Seat Comparison",
  "app.computerName": "Computer Name",
  "app.extNumber": "Extension Number",
  "app.createCrewSeatComparison": "Create Crew Seat Comparison",
  "app.updateCrewSeatComparison": "Update Seat Comparison",
  "app.teDailyLog": "Daily Log",
  "app.videoId": "Video ID",
  "app.dashboard": "Dashboard",
  "app.membershipPBI": "Membership PBI",
  "app.teMonthlyReport": "Telephone English PBI",
  "app.newVideoSubType": "New Sub Type",
  "app.capModify": "CAP Modify",
  "app.updateCapApplyRequestDate": "Update Cap Apply Request Date",
  "app.yesterday": "Last TE Day",
  "app.oneDay": "Specific Day",
  "app.dateRange": "Range of Days",
  "app.allNotSend": "All items (not send)",
  "app.alreadySent": "Items already send",
  "app.dlNameListExcel": "Download name list",
  "app.dlCertificateListExcel": "Download certificate list",
  "app.updateCapSendDate": "Update Send Date",
  "app.sendDate": "Send Date",
  "app.modifyHoliday": "Holidays in {year}",
  "app.sendByAction": "By Action",
  "app.sendByPromote": "Promote",
  "app.sendType": "Type",
  "app.internalList": "Internal Name List",
  "app.eventTypes": "Event Types",
  "app.eventMainType": "Main Type",
  "app.eventSubType": "Sub Type",
  "app.eventMainTypeName": "Main Type Name",
  "app.eventSubTypeName": "Sub Type Name",
  "app.createEventMainType": "Create New Main Type",
  "app.createEventSubType": "Create New Sub Type",
  "app.eventTypeName": "Type Name",
  "app.updateEventMainType": "Update Main Type",
  "app.updateEventSubType": "Update Sub Type",
  "app.eventFees": "Event Fees",
  "app.regularEvent": "Regular Event",
  "app.englishCarnival": "English Carnival",
  "app.eventNotice": "Event Type Description and Notice",
  "app.createVenue": "Create New Venue",
  "app.appEventBanner": "Event Banner Template",
  "app.appEventBannerCreate": "Create Event Banner Template",
  "app.appEventBannerUpdate": "Update Event Banner Template",
  "app.eventTopic": "Event Topic",
  "app.eventTopicCreate": "Create New Event Topic",
  "app.eventWords": "Event Words",
  "app.eventProps": "Event Props",
  "app.amusementTax": "Amusement Tax",
  "app.eventSessions": "Event Sessions",
  "app.eventExtraFees": "Event Extra Fees",
  "app.regionEventBanner": "Event Banner by Region",
  "app.crewType": "Crew Type Setting",
  "app.crewList": "Crew List Setting",
  "app.crewPointValue": "Crew Points Value",
  "app.crewPoint": "Crew Points",
  "app.eventPreviewDoc": "Event Preview Document",
  "app.appHomeBg": "APP Home Background",
  "app.eventExtraFeesMaintain": "Event Extra Fees",
  "app.crossMarketUser": "Cross Market User",
  "app.importantNotice": "Important Notice",
  "app.selectBannerTime": "Select Time",
  "app.wfcGeneral": "WFC General PBI",
  "app.wfcCAP": "WFC CAP PBI",
  "app.capLessonName": "Lesson Name",
  "app.capListWarningTitle": "ARE YOU SURE",
  "app.capListWarningDesc": "This action is irreversible, please confirm again",
  "app.capUnReview": "Submissions not reviewed yet: {count}",
  "app.capExpired": "Delayed Submissions (over 14 days): ",
  "app.giftLabelWarningTitle": "ARE YOU SURE",
};

export default en;

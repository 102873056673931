import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/videosByPerformerActions';

export interface VideosByPerformerState {
  isLoading: boolean;
  error: string | null;
  performers: {
    performerId: number;
    performerName: string;
  }[];
  videos: {
    seq: number;
    performerId: number;
    performerName: string;
    songNo: string;
    videoLink: string;
    songName: string;
    videoUrl: string;
    onlineStartDt: string;
    onlineEndDt: string;
    seasonOfPromote: string;
    isPublic: boolean;
  }[];
  selectVideo: {
    seq: number;
    performerId: number;
    performerName: string;
    songNo: string;
    videoLink: string;
    songName: string;
    videoUrl: string;
    onlineStartDt: string;
    onlineEndDt: string;
    seasonOfPromote: string;
    isPublic: boolean;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  performers: [],
  videos: [],
  selectVideo: null,
};

const videosByPerformerReducer = (
  state: VideosByPerformerState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PERFORMERS_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_PERFORMERS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_PERFORMERS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_FC_VIDEOS_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_FC_VIDEOS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        videos: action.payload,
        selectVideo: null,
      };
    case ActionType.FETCH_FC_VIDEOS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: action.payload,
      };
    case ActionType.FETCH_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.CREATE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.CREATE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        videos: [...state.videos, action.payload],
        selectVideo: null,
      };
    case ActionType.CREATE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.UPDATE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.UPDATE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        videos: state.videos.map((video) => {
          if (video.seq === action.payload.seq) {
            return action.payload;
          }
          return video;
        }),
        selectVideo: null,
      };
    case ActionType.UPDATE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.DELETE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.DELETE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        videos: state.videos.filter((video) => video.seq !== action.payload),
        selectVideo: null,
      };
    case ActionType.DELETE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        videos: state.videos,
        selectVideo: null,
      };
    default:
      return state;
  }
};

export default videosByPerformerReducer;

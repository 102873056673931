import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/video/videoTypeActions';

interface VideoTypesState {
  isLoading: boolean;
  error: string | null;
  mainTypes: {
    videoMainType: string;
    videoMainTypeDesc: string;
  }[];
  selectMainType: {
    videoMainType: string;
    videoMainTypeDesc: string;
  } | null;
  selectSubTypes:
    | {
        videoMainType: string;
        videoSubType: string;
        videoSubTypeDesc: string;
        imageUrl: string;
      }[]
    | undefined;
  selectSubType: {
    videoMainType: string;
    videoSubType: string;
    videoSubTypeDesc: string;
    imageUrl: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  mainTypes: [],
  selectMainType: null,
  selectSubTypes: undefined,
  selectSubType: null,
};

const videoTypesReducer = (
  state: VideoTypesState = initialState,
  action: Action
): VideoTypesState => {
  switch (action.type) {
    case ActionType.FETCH_VIDEO_MAIN_TYPES_LIST_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_MAIN_TYPES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: action.payload,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_MAIN_TYPES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_MAIN_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_MAIN_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: action.payload,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_MAIN_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_MAIN_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_MAIN_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: [...state.mainTypes, action.payload],
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_MAIN_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_MAIN_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_MAIN_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes.map((type) => {
          if (type.videoMainType === action.payload.videoMainType) {
            return action.payload;
          }
          return type;
        }),
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_MAIN_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_MAIN_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_MAIN_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes.filter(
          (type) => type.videoMainType !== action.payload
        ),
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_MAIN_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPES_LIST_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: action.payload,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: action.payload,
      };
    case ActionType.FETCH_VIDEO_SUB_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_SUB_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_SUB_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.CREATE_VIDEO_SUB_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_SUB_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_SUB_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.UPDATE_VIDEO_SUB_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_SUB_TYPE_START:
      return {
        isLoading: true,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_SUB_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: state.selectSubTypes?.filter(
          (type) => type.videoSubType !== action.payload
        ),
        selectSubType: null,
      };
    case ActionType.DELETE_VIDEO_SUB_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        mainTypes: state.mainTypes,
        selectMainType: null,
        selectSubTypes: undefined,
        selectSubType: null,
      };
    default:
      return state;
  }
};

export default videoTypesReducer;

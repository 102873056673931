import { IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout, Space, Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import './SignIn.css';
import Footer from '../../components/footer/Footer';
import Logo from '../../components/logo/Logo';
import { loginRequest } from '../../auth/authConfig';

interface SignInPageProps {
  instance: IPublicClientApplication;
}

const SignIn = ({ instance }: SignInPageProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { Content } = Layout;

  const handleSignIn = () => {
    if (!isAuthenticated) {
      instance.loginPopup(loginRequest).catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <Content className="content">
        <Space
          direction="vertical"
          size="large"
          style={{ alignItems: 'center' }}
        >
          <Logo width={250} />
          <Button
            onClick={handleSignIn}
            className="sign-in-btn"
            icon={<LoginOutlined style={{ marginRight: 10 }} />}
          >
            <FormattedMessage id="app.signIn" />
          </Button>
        </Space>
      </Content>
      <Footer />
    </Layout>
  );
};

export default SignIn;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/crossMarketUserActions';

export interface CrossMarketUserState {
  isLoading: boolean;
  error: string | null;
  crossMarketUsers: {
    userId: String;
    userName: String;
    email: String;
    utcCdt: String;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  crossMarketUsers: [],
};

const crossMarketUserReducer = (
  state: CrossMarketUserState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CROSS_MARKET_USER_LIST_START:
      return {
        isLoading: true,
        error: null,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.FETCH_CROSS_MARKET_USER_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crossMarketUsers: action.payload,
      };
    case ActionType.FETCH_CROSS_MARKET_USER_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.CREATE_CROSS_MARKET_USER_START:
      return {
        isLoading: true,
        error: null,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.CREATE_CROSS_MARKET_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crossMarketUsers: [...state.crossMarketUsers, action.payload],
      };
    case ActionType.CREATE_CROSS_MARKET_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.UPDATE_CROSS_MARKET_USER_START:
      return {
        isLoading: true,
        error: null,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.UPDATE_CROSS_MARKET_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crossMarketUsers: state.crossMarketUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            return action.payload;
          }
          return user;
        }),
      };
    case ActionType.UPDATE_CROSS_MARKET_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.DELETE_CROSS_MARKET_USER_START:
      return {
        isLoading: true,
        error: null,
        crossMarketUsers: state.crossMarketUsers,
      };
    case ActionType.DELETE_CROSS_MARKET_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crossMarketUsers: state.crossMarketUsers.filter(
          (user) => user.userId !== action.payload
        ),
      };
    case ActionType.DELETE_CROSS_MARKET_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crossMarketUsers: state.crossMarketUsers,
      };
    default:
      return state;
  }
};

export default crossMarketUserReducer;

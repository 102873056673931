import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/extraFeeActions';

interface ExtraFeeState {
  isLoading: boolean;
  error: string | null;
  memberList: {
    seqId: number;
    memberNo: string;
    amount: number;
    memberName: string;
    paidTypeDesc: string;
    remark: string;
    toPaymentFlag: String;
  }[];
  notToPaymentMemberList: {
    seqId: number;
    memNo: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  memberList: [],
  notToPaymentMemberList: [],
};

const extraFeeReducer = (
  state: ExtraFeeState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_EXTRA_FEES_LIST_START:
      return {
        isLoading: true,
        error: null,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.FETCH_EVENT_EXTRA_FEES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        memberList: action.payload,
        notToPaymentMemberList: action.payload
          .filter((member) => member.toPaymentFlag === '')
          .map((member) => ({
            seqId: member.seqId,
            memNo: member.memberNo,
          })),
      };
    case ActionType.FETCH_EVENT_EXTRA_FEES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.UPDATE_EXTRA_FEE_START:
      return {
        isLoading: true,
        error: null,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.UPDATE_EXTRA_FEE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        memberList: state.memberList.map((member) => {
          if (member.seqId === action.payload.seqId) {
            return action.payload;
          }
          return member;
        }),
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.UPDATE_EXTRA_FEE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.UPDATE_TO_PAYMENT_START:
      return {
        isLoading: true,
        error: null,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    case ActionType.UPDATE_TO_PAYMENT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        memberList: [],
        notToPaymentMemberList: [],
      };
    case ActionType.UPDATE_TO_PAYMENT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        memberList: state.memberList,
        notToPaymentMemberList: state.notToPaymentMemberList,
      };
    default:
      return state;
  }
};

export default extraFeeReducer;

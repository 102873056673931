import { Dispatch, SetStateAction } from 'react';
import { Layout, Button, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import './Header.css';
import Logo from '../logo/Logo';

export interface HeaderProps {
    currentUser: string;
    instance: IPublicClientApplication;
    setLocale: Dispatch<SetStateAction<string>>;
    locale: string;
}

const Header = ({ currentUser, instance, setLocale, locale }: HeaderProps) => {
    const { Header } = Layout;
    const menu = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => setLocale('zh-TW')}
                disabled={locale === 'zh-TW'}
            >
                <p className="lang-item">繁中(台灣)</p>
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => setLocale('en')}
                disabled={locale === 'en'}
            >
                <p className="lang-item">English</p>
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="header">
            <Link to="/">
                <Logo width={150} />
            </Link>
            <div className="sign-out-container">
                <p>Hello, {currentUser}</p>
                <Button
                    onClick={() => {
                        window.localStorage.clear();
                        instance.logoutPopup();
                    }}
                    className="sign-out-btn"
                    icon={<LogoutOutlined style={{ marginRight: 5 }} />}
                    size="small"
                >
                    <FormattedMessage id="app.signOut" />
                </Button>
                <Menu
                    className="lang-menu"
                    mode="horizontal"
                    onClick={({ key }) => {
                        if (key === 'zh-TW' || key === 'en') {
                            setLocale(key);
                        }
                    }}
                    selectedKeys={[locale]}
                >
                    <Menu.Item key="zh-TW" disabled={locale === 'zh-TW'} style={{ backgroundColor: '#004680' }}>
                        <span className="lang-item" style={{color:'#fff'}}>繁中(台灣)</span>
                    </Menu.Item>
                    <Menu.Item key="en" disabled={locale === 'en'} style={{ backgroundColor: '#004680' }}>
                        <span className="lang-item" style={{color:'#fff'}}>English</span>
                    </Menu.Item>
                </Menu>
            </div>
        </Header>
    );
};

export default Header;

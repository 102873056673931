import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/cap/capLabelPrintActions';

export interface CapLabelState {
  isLoading: boolean;
  error: string | null;
  capLabels: {
    seq: number;
    capSendDate: string;
    memberNumber: string;
    memberName: string;
    memberSex: string;
    zipCode: string;
    address: string;
    childName: string;
    childBirthday: string;
    giftName: string;
  }[];
  capLabelsByRange: {
    seq: number;
    capSendDate: string;
    memberNumber: string;
    memberName: string;
    memberSex: string;
    zipCode: string;
    address: string;
    childName: string;
    childBirthday: string;
    giftName: string;
  }[];
  capSendDates: string[];
  downloadReady: boolean;
}

const initialState = {
  isLoading: false,
  error: null,
  capLabels: [],
  capLabelsByRange: [],
  capSendDates: [],
  downloadReady: false,
};

const capLabelReducer = (
  state: CapLabelState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CAP_LABELS_LIST_START:
      return {
        isLoading: true,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.FETCH_CAP_LABELS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capLabels: action.payload.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: action.payload.capSendDates,
        downloadReady: false,
      };
    case ActionType.FETCH_CAP_LABELS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.FETCH_CAP_LABELS_LIST_BY_RANGE_START:
      return {
        isLoading: true,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.FETCH_CAP_LABELS_LIST_BY_RANGE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: action.payload.capLabels,
        capSendDates: action.payload.capSendDates,
        downloadReady: false,
      };
    case ActionType.FETCH_CAP_LABELS_LIST_BY_RANGE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.UPDATE_CAP_LABELS_STATUS_START:
      return {
        isLoading: true,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.UPDATE_CAP_LABELS_STATUS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: true,
      };
    case ActionType.UPDATE_CAP_LABELS_STATUS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: false,
      };
    case ActionType.DL_CAP_NAME_LIST_START:
      return {
        isLoading: true,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    case ActionType.DL_CAP_NAME_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    case ActionType.DL_CAP_NAME_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    case ActionType.DL_CAP_CERTIFICATE_LIST_START:
      return {
        isLoading: true,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    case ActionType.DL_CAP_CERTIFICATE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    case ActionType.DL_CAP_CERTIFICATE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capLabels: state.capLabels,
        capLabelsByRange: state.capLabelsByRange,
        capSendDates: state.capSendDates,
        downloadReady: state.downloadReady,
      };
    default:
      return state;
  }
};

export default capLabelReducer;

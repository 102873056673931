import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/userPermissionActions';

export interface UserPermissionState {
  isLoading: boolean;
  error: string | null;
  users: {
    empCode: string;
    englishName: string;
    chineseName: string;
    emailAddress: string;
    groupName: string;
  }[];
  selectUser: {
    empCode: string;
    englishName: string;
    chineseName: string;
    emailAddress: string;
    groupName: string;
  } | null;
  employees: {
    code: string;
    chineseName: string;
    englishName: string;
    email: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  selectUser: null,
  employees: [],
};

const userPermissionReducer = (
  state: UserPermissionState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_USERS_LIST_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_USERS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: action.payload,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_USERS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.DELETE_USER_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.DELETE_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: state.users.filter((user) => user.empCode !== action.payload),
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.DELETE_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_EMPLOYEES_LIST_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_EMPLOYEES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: state.users,
        selectUser: null,
        employees: action.payload,
      };
    case ActionType.FETCH_EMPLOYEES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.CREATE_USER_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.CREATE_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: [...state.users, action.payload],
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.CREATE_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.UPDATE_USER_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.UPDATE_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: state.users.map((user) => {
          if (user.empCode === action.payload.empCode) {
            return action.payload;
          }
          return user;
        }),
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.UPDATE_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_USER_START:
      return {
        isLoading: true,
        error: null,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    case ActionType.FETCH_USER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        users: state.users,
        selectUser: action.payload,
        employees: state.employees,
      };
    case ActionType.FETCH_USER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        users: state.users,
        selectUser: null,
        employees: state.employees,
      };
    default:
      return state;
  }
};

export default userPermissionReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/app/regionEventBanner';

interface RegionEventBannerState {
  isLoading: boolean;
  error: string | null;
  topics: {
    region: string;
    mainNo: string;
    actName: string;
    actType: string;
    actTypeName: string;
    actDateB: string;
    actDateE: string;
    imageUrl: string | null;
    utcCdt: string | null;
    utcUdt: string | null;
    webDateB: string;
    actContent: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  topics: [],
};

const regionEventBannerReducer = (
  state: RegionEventBannerState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_REGION_EVENT_TOPIC_LIST_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
      };
    case ActionType.FETCH_REGION_EVENT_TOPIC_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: action.payload,
      };
    case ActionType.FETCH_REGION_EVENT_TOPIC_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
      };
    case ActionType.CREATE_REGION_BANNER_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
      };
    case ActionType.CREATE_REGION_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: state.topics.map((topic) => {
          if (topic.mainNo === action.payload.mainNo) {
            return action.payload;
          }
          return topic;
        }),
      };
    case ActionType.CREATE_REGION_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
      };
    case ActionType.UPDATE_REGION_BANNER_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
      };
    case ActionType.UPDATE_REGION_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: state.topics.map((topic) => {
          if (topic.mainNo === action.payload.mainNo) {
            return action.payload;
          }
          return topic;
        }),
      };
    case ActionType.UPDATE_REGION_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
      };
    case ActionType.DELETE_REGION_BANNER_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
      };
    case ActionType.DELETE_REGION_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: state.topics.map((topic) => {
          if (topic.mainNo === action.payload) {
            return { ...topic, imageUrl: null };
          }
          return topic;
        }),
      };
    case ActionType.DELETE_REGION_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
      };
    default:
      return state;
  }
};

export default regionEventBannerReducer;

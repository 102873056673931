import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/crew/crewOnlineEventActions';

export interface CrewOnlineEventState {
  isLoading: boolean;
  error: string | null;
  crewZoomInfoList: {
    crewNo: string;
    crewName: string;
    firstName: string;
    familyName: string;
    zoomMeetingUrl: string;
    zoomMeetingId: string;
    zoomMeetingPasscode: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  crewZoomInfoList: [],
};

const crewOnlineEventReducer = (
  state: CrewOnlineEventState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CREW_ZOOM_INFO_LIST_START:
      return {
        isLoading: true,
        error: null,
        crewZoomInfoList: state.crewZoomInfoList,
      };
    case ActionType.FETCH_CREW_ZOOM_INFO_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewZoomInfoList: action.payload,
      };
    case ActionType.FETCH_CREW_ZOOM_INFO_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewZoomInfoList: state.crewZoomInfoList,
      };
    case ActionType.UPDATE_CREW_ZOOM_INFO_START:
      return {
        isLoading: true,
        error: null,
        crewZoomInfoList: state.crewZoomInfoList,
      };
    case ActionType.UPDATE_CREW_ZOOM_INFO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        crewZoomInfoList: action.payload,
      };
    case ActionType.UPDATE_CREW_ZOOM_INFO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        crewZoomInfoList: state.crewZoomInfoList,
      };
    default:
      return state;
  }
};

export default crewOnlineEventReducer;

import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/cap/capCheckingActions';

interface CapCheckingState {
  isLoading: boolean;
  error: string | null;
  capHomeworksList: {
    seq: number;
    memNo: string;
    seqNo: string;
    chineseNameOfChild: string;
    englishNameOfChild: string;
    age: string;
    capNo: string;
    capLessonName: string;
    capLessonDetail: {
      capLessonDetailNo: string;
      capLessonDetailName: string;
      newCapLessonDetailName: string;
    }[];
    soundsOfChild: string[];
    requestDate: string;
    checkingDate: string;
    checkingResult: string;
  }[];
  capHomeworksListByMember: {
    seq: number;
    memNo: string;
    seqNo: string;
    chineseNameOfChild: string;
    englishNameOfChild: string;
    age: string;
    capNo: string;
    capLessonName: string;
    capLessonDetail: {
      capLessonDetailNo: string;
      capLessonDetailName: string;
      newCapLessonDetailName: string;
    }[];
    soundsOfChild: string[];
    requestDate: string;
    checkingDate: string;
    checkingResult: string;
  }[];
  counts: {
    unreviewedCount: number;
    expiredCount: number;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  capHomeworksList: [],
  capHomeworksListByMember: [],
  counts: null,
};

const capCheckingReducer = (
  state: CapCheckingState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_START:
      return {
        isLoading: true,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capHomeworksList: action.payload,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_START:
      return {
        isLoading: true,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: action.payload,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.CAP_CHECKING_START:
      return {
        isLoading: true,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.CAP_CHECKING_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capHomeworksList: action.payload.unreviewedList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: {
          unreviewedCount: action.payload.unreviewedCount,
          expiredCount: action.payload.expiredCount,
        },
      };
    case ActionType.CAP_CHECKING_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_COUNT_START:
      return {
        isLoading: true,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    case ActionType.FETCH_CAP_COUNT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: action.payload,
      };
    case ActionType.FETCH_CAP_COUNT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        capHomeworksList: state.capHomeworksList,
        capHomeworksListByMember: state.capHomeworksListByMember,
        counts: state.counts,
      };
    default:
      return state;
  }
};

export default capCheckingReducer;
